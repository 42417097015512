/*------------------------------------*\
    $SEARCH RESULT
\*------------------------------------*/

.search-result {

    .search-result-title {
        display:flex;
        flex-direction:row;
        flex-wrap:nowrap;
        align-items:center;
        justify-content:space-between;
    }
}
