/*------------------------------------*\
    $CONTACT
\*------------------------------------*/

.contact-heading {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

.contact {
    display: inline-grid;
    grid-template-columns: minmax(50px, auto) auto;
    grid-gap: 16px $base-spacing-unit;
    grid-gap: 16px var(--typography-margin);
    grid-auto-flow: row;

    dt {
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        white-space: nowrap;
    }
}

.contact-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contact-wrap {
    display: block;
    margin-bottom: 0;

    dd {
        margin-bottom: $base-spacing-unit;
        margin-bottom: var(--typography-margin, $base-spacing-unit);
    }
}

.contact-email {
    color: $colour-ui-dark;
    text-decoration: underline;

    &:hover {
        color: $colour-primary;
    }
}


/**
 * Contact an individual
 */

.contact-person-group {
    @include media-query(desk) {
        &:not(:last-child) {
            margin-bottom: $base-spacing-unit;
        }
    }
}

.contact-person-group-sml {
    &:not(:last-child) {
        margin-bottom: 0;
    }

    &:last-child {
        margin-bottom: halve($base-spacing-unit);
    }
}

.contact-person-heading {
    @include media-query(desk) {
        background-color: #ebebeb;
        padding: 18px $base-spacing-unit 18px 105px;
    }
}

.contact-person-heading-sml {
    @include font-size(14px);
    background-color: #ebebeb;
    color: #808080;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    padding: 10px;
    margin-bottom: halve($base-spacing-unit);
}

.contact-person {
    color: #808080;
}

.contact-person-media {
    display: flex;
    align-items: center;
}

.contact-person-title {
    color: $colour-ui-dark;

    &:not(:only-child) {
        margin-bottom: 0;
    }
}

.contact-person-thumb {
    flex-shrink: 0;
    width: 75px;
    margin-right: $base-spacing-unit;

    &,
    img {
        border-radius: 100%;
    }

    .contact-person-group-sml & {
        margin-bottom: halve($base-spacing-unit);
        width: 48px;
    }

    @media screen and (max-width: 419px) {
        width: 38px;
        height: 38px;
    }
}

.contact-person-body {
    flex: 1 0 0%;

    & > ul > li {
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        align-items:center;
        line-height:1.4;
    }

    .team-info {
        line-height:1.4;
    }
    .team-email {
        color: $colour-secondary;
        word-break:break-all;

        &:hover {
            color: $colour-ui-dark;
        }
    }

    .contact-person-group-sml & {
        > :last-child {
            margin-bottom: halve($base-spacing-unit);
        }
    }
}
