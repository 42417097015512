/*------------------------------------*\
    $PAGE
\*------------------------------------*/

.page {
    overflow: hidden;
    margin-top: 63px; /* Height of header */

    @include media-query(nav-portable) {
        margin-bottom: 47px;
    }

    @include media-query(nav) {
        margin-top: 203px; /* Height of header */
    }

    @media screen and (min-width: 1150px) {
        margin-top: 142px;
    }
}

.page-main {
    overflow: hidden;

    @include media-query(nav-portable) {
        transition: transform $base-anim-speed ease-in-out;

        .has-active-nav & {
            transform: translateX(-100%) translateX(70px);

            @media screen and (min-width: 370px) {
                transform: translateX(-300px);
            }
        }
    }
}

.page-list {

    & > li {
        margin-bottom: $base-spacing-unit; padding:35px;
        background:$colour-ui;
    }

    .page-title {
        @include font-size(20px, 24px);
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }

    .page-footer {
        display: flex;
        margin-top: auto;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;

        .btn {
            @include font-size(15px, $base-spacing-unit);
        }
    }
}
