/*------------------------------------*\
    $SIDENAV
\*------------------------------------*/

.sidenav {
    margin-bottom: double($base-spacing-unit);

    // @include media-query(desk) {
    //     margin-bottom: treble($base-spacing-unit);
    // }
}

.sidenav-item {
    padding-left: 30px;

    &:not(:last-child) {
        padding-bottom: 26px;
    }

    .sidenav > & {
        border-left: 3px solid #e6e6e6;

        &.is-current-parent {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                left: -3px;
                top: 0;
                bottom: 0;
                border-left: 3px solid $colour-primary;
            }

            &:not(:last-child)::before {
                bottom: 26px;
            }
        }
    }
}

.sidenav-link {
    @include font-size(16px);
    display: inline-block;
    color: #474747;

    &:hover,
    &.is-current {
        color: $colour-primary;
    }

    .is-current-parent > &,
    &.is-current {
        font-weight: $font-weight-semibold;
    }

    &.is-current {
        text-decoration: underline;
    }

    .sidenav-sub > & {
        padding-right: 24px;
    }
}

.sidenav-sub {
    position: relative;
}

.sidenav-subtoggle {
    position: absolute;
    right: 0;
    top: 12px;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #b2b2b2;
        transform: translate(-50%, -50%);
        transition: transform $base-anim-speed ease-in-out;

        .sidenav-sub.is-active & {
            transform: translate(-50%, -50%) rotate(0.25turn);
        }
    }
}

.sidenav-sublist {
    display: none;
    margin-bottom: 0;

    &::before {
        content: "";
        display: block;
        height: 26px;
    }

    .sidenav-sub.is-current-parent & {
        display: block;
    }
}

/*------------------------------------*\
    $STICKY SIDEBAR
\*------------------------------------*/

.sticky-sidebar {
    will-change: min-height;
}
.sticky-sidebar-inner{
    transform: translate(0, 0); /* For browsers don't support translate3d. */
    transform: translate3d(0, 0, 0);
    will-change: position, transform;
}
