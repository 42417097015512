/*------------------------------------*\
    $LANDMARK
\*------------------------------------*/

.landmark {
    @include media-query(desk) {
        display: flex;
        flex-direction: column;
        margin-bottom: $base-spacing-unit;
    }
}
