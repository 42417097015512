/*------------------------------------*\
    $BOX
\*------------------------------------*/

/**
 * Box off content
 */

.box {
    margin-bottom: $base-spacing-unit;
    padding: $base-spacing-unit $base-spacing-unit 1px;
}

.box-lrg {

    @include media-query(palm) {
        margin-left: -$base-spacing-unit;
        margin-right: -$base-spacing-unit;
    }

    @include media-query(desk) {
        padding: double($base-spacing-unit) double($base-spacing-unit) $base-spacing-unit;
    }
}

.box-sml {

}

.box-primary {
    background-color: $colour-primary;
}

.box-ui {
    background-color: #ededed;
}

.box-ui-light {
    background-color: $colour-ui-light;
}

.box-border {
    border: 2px solid #e6e6e6;

    @include media-query(lap) {
        padding-right: 0;
    }
}

.box-header {
    @include font-size(16px, 24px);
    border-bottom: 2px solid $colour-ui;
    color: #a6a6a6;
    margin-top: -$base-spacing-unit;
    margin-left: -$base-spacing-unit;
    margin-right: -$base-spacing-unit;
    padding: 18px $base-spacing-unit;
    text-transform: uppercase;
    text-align: center;
    font-weight: $font-weight-semibold;
}


/**
 * Box CTAs
 */

.box-link-list {
    margin-left: -22px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
}

.box-link-wrapper {
    display: flex;
    width: calc(100% - 22px);
    margin-left: 22px;
    margin-bottom: 22px;

    @include media-query(lap) {
        width: calc(50% - 22px);
    }
}

.box-link {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: $colour-ui;
    padding: 11px 0 11px 40px;
    transition: background-color $base-anim-speed, color $base-anim-speed;
    color: $colour-ui-dark;

    &:hover {
        background-color: $colour-quaternary;
        color: $colour-ui-light;
    }
}

.box-link-body {
    @include font-size(15px, 18px);
    width: calc(70% - 40px);
    font-weight: $font-weight-semibold;
}

.box-link-figure {
    width: 30%;
    margin-left: 40px;

    &#{&} {
        margin-bottom: 0;
        background-color: $colour-ui-dark;
    }
}

.box-highlight {
    padding: 24px;

    &.box-highlight-primary {
        color: $colour-ui-light;
        background-color: $colour-primary;
    }
    &.box-highlight-tertiary {
        color: $colour-ui-dark;
        background-color: $colour-tertiary;
    }
}
