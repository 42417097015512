/*------------------------------------*\
    $BREADCRUMBS
\*------------------------------------*/

.breadcrumbs {
    display: flex;
    // margin-bottom: -30px;
    margin-bottom: 0;

    @include media-query(portable) {
        display: none;
    }
}

.breadcrumbs-item {
    position: relative;
    padding-top: 18px;
    padding-bottom: 18px;
    border-bottom: 2px solid $colour-ui;
    color: #999;

    &:not(:last-child) {
        padding-right: $base-spacing-unit;
    }

    &:first-child {
        padding-left: 25px;
    }

    &:last-child {
        padding-right: 25px;

        .icon {
            display: none;
        }
    }

    .icon {
        position: absolute;
        top: 50%;
        right: halve($base-spacing-unit);
        transform: translate(50%, -50%);
        width: 5px;
        height: 8px;
    }

    .breadcrumbs-borderless & {
        border-bottom: none;
    }
}

.breadcrumbs-link {
    @include font-size(14px);
    display: block;
    color: currentColor;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;

    &:hover {
        color: $colour-ui-dark;
    }

    :last-child > & {
        font-weight: $font-weight-semibold;
        color: $colour-ui-dark;
    }
}
