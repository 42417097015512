/*------------------------------------*\
    $HIDE
\*------------------------------------*/

/**
 * Hide block level elements based on a media query
 */

@include media-query(palm) {
    .hide-palm {
        display: none;
    }
}

@include media-query(lap) {
    .hide-lap {
        display: none;
    }
}

@include media-query(portable) {
    .hide-portable {
        display: none;
    }
}

@include media-query(desk) {
    .hide-desk {
        display: none;
    }
}

@include media-query(nav-portable) {
    .hide-nav-portable {
        display: none;
    }
}

@include media-query(nav) {
    .hide-nav {
        display: none;
    }
}
