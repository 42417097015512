/*------------------------------------*\
    $MODAL
\*------------------------------------*/

/**
 * Setup positioning and scrolling of modals
 */

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background-color: rgba(#d9d9d9, .9);
    transition: background-color $base-anim-speed, opacity $base-anim-speed, visibility 0s $base-anim-speed;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    padding: $base-spacing-unit;

    &.is-active {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
        overflow: auto;
        overflow-x: hidden;
        backface-visibility: hidden;
        -webkit-overflow-scrolling: touch;
    }

    &::before {
        content: "";
        vertical-align: middle;
        line-height: 100%;
        height: 100%;
        display: inline-block;
        margin-left: -0.3em;
    }
}

.modal-secondary {
    background-color: rgba($colour-secondary, .96);
}


/**
 * Main body block styles
 */

.modal-body {
    position: relative;
    z-index: 1;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    background-color: $colour-ui-light;
}


/**
 * Close button
 */

.modal-close {
    @include font-size(13px, 18px);
    position: absolute;
    top: -15px;
    right: -15px;
    text-align: center;
    z-index: 1;
    @include media-query(desk) {
        position: fixed;
        top: 45px;
        right: 45px;
    }

    &:hover {
        .modal-close-icon {
            background-color: $colour-ui-dark;
            color: $colour-ui-light;
        }
    }
}

.modal-close-icon {
    display: block;
    position: relative;
    border-radius: 99px;
    background-color: $colour-secondary;
    color: #d1ded6;
    text-align: center;
    width: 48px;
    height: 48px;
    transition: background-color $base-anim-speed, color $base-anim-speed;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 18px;
        height: 18px;
        transform: translate(-50%, -50%);
    }

    .modal-secondary & {
        background-color: #507d63;
        color: #dce5e0;
    }
}

.modal-close-text {
    @include font-size(13px, 18px);
    display: inline-block;
    color: $colour-secondary;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    margin-top: 3px;

    .modal-secondary & {
        color: #dce5e0;
    }

    @include media-query(portable) {
        @include visuallyhidden;
    }
}

.modal-content {
    display: none;
    width: calc(100vw - #{double($base-spacing-unit)});
    max-width: 945px;
    padding: $base-spacing-unit $base-spacing-unit 1px;

    &.is-active {
        display: block;
    }

    @include media-query(desk) {
        padding: 68px 68px (68px - $base-spacing-unit);
    }
}

.modal-content-sml {
    max-width: 750px;
}
.modal-content-video {
    padding: 0 !important;
    .video {
        margin-bottom: 0;
    }
}

.modal-content-lrg {
    max-width: 1116px;
}

.modal-content-xl {
    max-width: 1536px;
}


/**
 * Worldwide Modal
 */

.modal-worldwide {
    padding: 0;
    max-width: $container-width;

    &.is-active {
        display: flex;
    }
}

.modal-worldwide-figure {
    width: 20%;
    margin-bottom: 0;

    &::before {
        display: none;
    }

    img {
        height:100% !important;
    }

    @include media-query(portable) {
        display: none;
    }
}

.modal-worldwide-body {
    @include media-query(desk) {
        width: 80%;
    }
}

.modal-worldwide-typography {
    padding: $base-spacing-unit $base-spacing-unit 1px;
    color: #808080;

    h1, h2, h3, h4, h5, h6 {
        color: $colour-ui-dark;
    }

    @include media-query(desk) {
        padding: 68px 68px (68px - $base-spacing-unit);
    }
}

.modal-worldwide-seal {
    background-color: $colour-royal-alt;
    padding: $base-spacing-unit $base-spacing-unit 1px;

    &,
    .royal-seal-caption {
        color: $colour-ui-light;
    }

    @include media-query(desk) {
        padding-left: 68px;
        padding-right: 68px;
    }
}
