/*------------------------------------*\
    $HOME
\*------------------------------------*/

.home-heading {
    font-weight: $font-weight-semibold;
}

.featured-carousel {
    .carousel-slide {
        display: flex;
        flex-direction: column;
        padding: 20px;
        @media screen and (max-width:768px) {
            padding: 0;
        }
        .carousel-link {
            @media screen and (max-width:768px) {
                padding: 0;
            }
        }
        .featured-product-heading {
            display: block;
            font-size: 18px;
            padding: 10px 0;
            margin: 0;
        }
    }

}


.home-find-machinery {

    .home-heading {
        margin-bottom:$base-spacing-unit / 2;
    }

    & > ul {
        align-items:flex-end;
        padding-bottom:$base-spacing-unit;

        .field {

            @include media-query(desk) {
                margin-bottom:0;
            }
        }
    }
}
