/*------------------------------------*\
    $POST
\*------------------------------------*/

.post-band {
    position: relative;
}

.post-wrapper {
    @include media-query(palm) {
        margin-left: -6px;
        margin-right: -6px;
    }
}

.post-list {
    @include media-query(lap) {
        @include grid;
    }
}

.post-slider {
    margin-left: -4px;
    margin-right: -4px;

    &:not(.slick-initialized) {
        display: flex;

        @include media-query(lap) {
            .post-slide:nth-child(-n + 2) {
                display: flex !important;
                width: calc(50% - #{$base-spacing-unit});
            }
        }

        @include media-query(desk) {
            .post-slide:nth-child(-n + 3) {
                display: flex !important;
                width: calc(#{percentage(1/3)} - #{$base-spacing-unit});
            }
        }

        @include media-query(wide) {
            .post-slide:nth-child(-n + 4) {
                display: flex !important;
                width: calc(25% - #{$base-spacing-unit});
            }
        }
    }

    // .slick-list,
    .slick-track {
        display: flex;
    }

    @include media-query(lap) {
        margin-left: 0 - halve($base-spacing-unit);
        margin-right: 0 - halve($base-spacing-unit);
    }

    .slick-list {
        @include media-query(palm) {
            overflow: visible;
            width: 100%;
        }
    }
}

.post {
    position: relative;
    background-color: $colour-ui-dark;
    color: $colour-ui-light;
    display: flex;
    // flex: 1 0 auto;
    flex-direction: column;
    justify-content: flex-end;

    &::before {
        content: "";
        padding-top: percentage(4/5);
        display: block;
        pointer-events: none;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to top, rgba($colour-ui-dark, 1), rgba($colour-ui-dark, 0));
        pointer-events: none;
    }

    .post-list & {
        margin-bottom: $base-spacing-unit;
    }

    @include media-query(lap) {
        @include grid-item($base-spacing-unit, 1/2);
    }

    @include media-query(desk) {
        @include grid-width(1/3);
    }

    @include media-query(wide) {
        @include grid-width(1/4);
    }
}

.post-slide {
    margin-left: 4px;
    margin-right: 4px;

    @include media-query(lap) {
        margin-left: halve($base-spacing-unit);
        margin-right: halve($base-spacing-unit);
    }
}

.post-figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: cover;
    font-family: 'object-fit: cover';
    transition: opacity $base-anim-speed;

    &.lazyloaded {
        opacity: 0.9;

        &:hover {
            opacity: 1;
        }
    }
}

.post-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    position: relative;
    z-index: 2;
    margin-left: 35px;
    margin-right: 35px;
}

.post-icon {
    position: absolute;
    bottom: 100%;
    left: 0;
    margin-bottom: 16px;
    width: 28px;
    height: 28px;
    pointer-events: none;
}

.post-date {
    @include font-size(13px, 18px);
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    color: rgba($colour-ui-light, .5);
}

.post-title {
    @include font-size(20px, 24px);
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    text-shadow: 0px 2px 3px $colour-ui-dark;
}

.post-footer {
    display: flex;
    margin-top: auto;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;

    .btn {
        @include font-size(15px, $base-spacing-unit);
    }
}

.post-category {
    position: relative;
    padding-left: 25px;
    margin-right: halve($base-spacing-unit);

    .icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}

.span-date {
    @include font-size(16px, 24px);
    color:$colour-primary;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
}
