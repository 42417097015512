/*------------------------------------*\
    $SHORTLIST
\*------------------------------------*/

.shortlist {
    border-top:1px solid $colour-ui;

    .shortlist-item {
        display:flex;
        flex-direction:row;
        flex-wrap:nowrap;
        align-items:center;
        padding:4px 0;
        border-bottom:1px solid $colour-ui;

        .shortlist-item-thumb {
            display:block;
            width:80px; height:80px;

            img {
                display:block;
                width:80px; height:80px;
                object-fit:cover;
                font-family:'object-fit: cover;';
            }
        }

        .shortlist-item-text {
            flex:1;
            padding-left:18px;
            line-height:1.4;
        }

        .shortlist-item-title {
            color:$colour-ui-dark;
            font-weight:600;
        }
    }
}
