/*------------------------------------*\
    $PRINT
\*------------------------------------*/

.show-print {
    display:none !important;
}

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important; /* Black prints faster */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    .show-print {
        display:block !important;
    }

    .header-ui,
    .header-nav nav,
    .nav-toggle,
    .nav-back,
    footer,
    .main-sidebar,
    .breadcrumbs,
    .hide-print,
    .product-detail-filters,
    .pagination,
    .arrow-prev,
    .arrow-next,
    .gallery .slick-arrow,
    .notice {
        display: none !important;
    }

    .header {
        position: absolute;
    }

    .header-logo {
        position:absolute;
        top:0; right:0;
        z-index:1;
        height:48px;
    }

    .branch-info-header {
        position:absolute;
        top:0; left:0;
        z-index:1;
    }
    .branch-info-footer {
        margin-top:auto;
        line-height:1.4;
        font-size:12px;

        p {
            margin:0; margin-top:12px; padding:0;
        }
        .space-between {
            display:flex;
            flex-direction:row;
            flex-wrap:nowrap;
            align-items:flex-end;
            justify-content:space-between;
        }
    }

    h1.arrow-heading {
        margin-top:12px;
        text-align:left;
    }

    .product-detail-wrapper {
        border:none !important;
    }

    .product-detail-top {
        display:flex;
        flex-direction:row;
        flex-wrap:nowrap;
        justify-content:space-between;
        margin:0; padding:0;

        .gallery-print,
        .product-detail-info-wrapper {
            width:calc(50% - 12px);
        }
    }

    .product {
        width: calc(25% - 30px) !important;
        padding: 0;
    }

    .product-thumb {
        margin: 0 0 30px;
    }

    .product-footer {
        margin: 0 auto;
        padding: 0;
    }

    .product-tag {
        background-color: #fff !important;
    }

    .product-paidfor {
        background-color: #fff !important;
    }

    .bleed {
        margin: 0;
    }

    .product-info-item {
        margin-bottom: 0;
        width: calc(100% - 16px);
    }

    .gallery-print {
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        justify-content:space-between;
        margin:0 -2px;

        img:first-child {
            padding:0 2px 4px;
            width:100%; height:auto;
        }
        img:not(:first-child) {
            padding:0 2px;
            width:33.33%; height:auto;
        }
    }

    .product-detail-info {
        margin:0; padding:0;

        .data {
            margin:0; padding:0;
            width:100%;
            border-bottom:1px solid #d9dedb;
        }
        .data dt, .data dd {
            padding-left:0; padding-right:0;
            font-size:14px !important;
            border-width:1px;
        }
    }

    .product-detail-footer {
        margin:24px 0 0; padding:0;
    }
    .product-detail-price {
        position:static;
        padding:0;
        background:none;
        border:none;
        border-radius:0;
        transform:none;
        font-size:24px;
        font-weight:700;
    }

    .product-detail-typography {
        margin-top:24px;
    }

    .typography-features {
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        margin-top:-12px;
        font-size:14px !important;

        li {
            padding:6px 12px 0 0;
            width:calc(50% - 12px);
            border:none;
        }
    }

    .band {
        padding: 0 !important;
    }
}
