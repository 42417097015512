/*------------------------------------*\
    $GROUNDS-CARE
\*------------------------------------*/

.grounds-care-price {
    @include font-size(25px, 24px);
    font-weight: $font-weight-semibold;
}

.grounds-care-inc {
    @include font-size(13px);
    text-transform: uppercase;
    font-weight: $font-weight-bold;
}

.grounds-care-ex {
    @include font-size(15px);
    text-transform: uppercase;
    color: #727272;
}

.grounds-care-search {
    @include media-query(desk) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: treble($base-spacing-unit);

        .widget-search {
            margin-bottom: -$base-spacing-unit - 2px;
        }
    }
}

.grounds-care-typography {
    color: #808080;

    h1, h2, h3, h4, h5, h6 {
        color: $colour-ui-dark;
    }

    @include media-query(desk) {
        margin-top: double($base-spacing-unit);
        margin-bottom: double($base-spacing-unit);
    }
}

.grounds-care-table {
    width: 100%;

    &#{&} {
        th {
            @include font-size(13px);
            white-space: nowrap;
            text-align: left;
            padding-left: 0;
            text-transform: uppercase;
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    td {
        @include font-size(15px);
        width: 100%;
        color: #808080;
    }
}

.grounds-care-table-primary {
    border-radius: 3px;

    &#{&} {
        th, td {
            border: none;
            border-right: 1px solid $colour-ui-light;
            border-bottom: 1px solid $colour-ui-light;
            background-color: #e8ebea;
            color: $colour-ui-dark;

            @include media-query(desk) {
                @include font-size(17px);
                padding: 12px $base-spacing-unit;
            }
        }

        tbody > :first-child > :first-child {
            border-top-left-radius: 3px;
        }

        tbody > :first-child > :last-child {
            border-top-right-radius: 3px;
        }

        tbody > :last-child > :first-child {
            border-bottom-left-radius: 3px;
        }

        tbody > :last-child > :last-child {
            border-bottom-right-radius: 3px;
        }
    }
}

.grounds-care-slider {
    position: relative;
    background-color: $colour-ui;
    margin-bottom: 22px;

    .slick-arrow {
        background-color: #c4c5c4;
        color: #eaebea;
    }
}
