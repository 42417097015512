/*------------------------------------*\
    $HEADER
\*------------------------------------*/

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;

    @include media-query(nav-portable) {
        box-shadow: 0 1px 0 rgba(#000, .0);
        transition: box-shadow $base-anim-speed, transform $base-anim-speed ease-in-out;

        &.is-scrolled {
            box-shadow: 0 1px 0 rgba(#000, .08);
        }

        .has-active-nav & {
            transform: translateX(-100%) translateX(70px);

            @media screen and (min-width: 370px) {
                transform: translateX(-300px);
            }
        }
    }

    @include media-query(nav) {
        box-shadow: 0 1px 0 rgba(#000, .08);
    }
}

.header-ui {
    background-color: $colour-ui-dark;
    padding: 0 $base-spacing-unit;
    color: $colour-ui-light;

    @include media-query(nav-portable) {
        background-color: rgba($colour-ui-dark, .98);
        position: absolute;
        top: 100vh;
        top: calc(var(--vh, 1vh) * 100);
        left: 0;
        width: 100%;
        padding-top: 11px;
        padding-bottom: 11px;
        transform: translateY(-100%);
    }

    @include media-query(nav) {
    }
}

.header-ui-container {
    display: flex;
    justify-content: space-between;

    @include media-query(nav) {
        height: 54px;
        align-items: center;
    }
}

.header-nav {
    display: flex;
    align-items: center;
    background-color: $colour-ui-light;

    @include media-query(nav) {
        @media screen and (max-width: 1149px) {
            padding-bottom:62px;
            padding-right: $base-spacing-unit;
        }
    }
}

.header-controls {
    @include media-query(nav-portable) {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &,
        .nav-item {
            margin-bottom: 0;
        }
    }

    @include media-query(nav) {
        margin-right: 22px;
        // margin-right: 67.5px;

        &#{&} {
            margin-left: auto;
        }
    }
}

.header-controls-text {
    @include media-query(nav-portable) {
        @include visuallyhidden;
    }
}

.header-controls-aux {
    @media screen and (max-width: 1249px) {
        @include visuallyhidden;
    }
}

.header-logo {
    flex-shrink: 0;
    display: inline-block;
    margin: 16px auto;

    @include media-query(nav) {
        margin: 25px 22px 25px $base-spacing-unit;

        img {
            width: 176px;
        }
    }

    @media screen and (min-width: 800px) {
        margin-right: $base-spacing-unit;
    }
}

.header-affiliate {
    display: inline-block;
    line-height: 1;

    @include media-query(nav-portable) {
        margin-bottom: $base-spacing-unit;
    }
}

.header-banner {
    padding:15px 30px;
    background:$colour-primary;

    &:not(.is-active) {
        display:none !important;
        opacity:0 !important;
        visibility:hidden !important;
    }
    &.is-active {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        align-items:center;
    }
}
    .header-banner--text {
        margin-bottom:-30px;
    }
    .header-banner--close {
        display:block;
        width:16px; height:16px;
        color:#FFF;

        svg {
            display:block;
            width:16px; height:16px;
        }
    }
