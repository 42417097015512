/*------------------------------------*\
    $LAZYLOAD
\*------------------------------------*/

.lazyload-wrapper {
    position: relative;
    background-color: $colour-ui;
    display: block;
    margin-bottom: $base-spacing-unit;

    &::before {
        content: "";
        display: block;
        padding-top: percentage(9/16);
    }
}

.lazyload-wrapper iframe:not(.lazyload),
.lazyload-figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    opacity: 0;
    transition: opacity $base-anim-speed;

    &.lazyloaded {
        opacity: 1;
    }
}

.lazyload-wrapper iframe:not(.lazyload) {
    opacity: 1;
}


/**
 * Custom ratios
 */

.lazyload-wrapper-5\:2 {
    &::before {
        padding-top: percentage(2/5);
    }
}

.lazyload-wrapper-4\:3 {
   &::before {
       padding-top: percentage(3/4);
   }
}

.lazyload-wrapper-2\:1 {
    &::before {
        padding-top: percentage(1/2);
    }
}

.lazyload-wrapper-1\:1 {
    &::before {
        padding-top: 100%;
    }
}

.lazyload-wrapper-custom {
    &::before {
        display: none;
    }
}


/**
 * Lazyload Logos
 */

.lazyload-wrapper-logo {
    border: 1px solid #e6e6e6;
    background-color: #fff;
    border-radius: 3px;

    .lazyload-figure {
        max-width: 80%;
        left: 50%;
        top: 20px;
        height: calc(100% - 40px);
        transform: translateX(-50%);
        object-fit: contain;
        font-family: 'object-fit: contain;';
    }
}
