/*------------------------------------*\
    $LOCK
\*------------------------------------*/

html.is-locked {
    &,
    body {
        overflow: hidden;
    }
}
