/*------------------------------------*\
    $ALERT
\*------------------------------------*/

.alert {
    position: fixed;
    padding: $base-spacing-unit treble($base-spacing-unit) $base-spacing-unit $base-spacing-unit;
    z-index: 100;
    opacity: 1;
    visibility: visible;
    left: halve($base-spacing-unit);
    right: auto;
    bottom: halve($base-spacing-unit);
    top: auto;
    @include font-size(16px);
    border-radius: 8px;
    max-width: calc(100% - #{$base-spacing-unit});
    width: auto;
    color: $colour-ui-light;
    background-color: rgba($colour-ui-dark, .95);
    box-shadow: 0 0 12px rgba(#000, .48);

    > * {
        margin-bottom: halve($base-spacing-unit);
    }

    > :last-child {
        margin-bottom: 0;
    }

    @media (min-width: 432px) {
        max-width: 384px;
    }

    @include media-query(nav-portable) {
        bottom: 47px + halve($base-spacing-unit);
    }
}

.alert-invalid {
    background-color: rgba($colour-invalid, .88);
}

.alert-valid {
    background-color: rgba($colour-valid, .88);
}

.alert-close {
    position: absolute;
    top: $base-spacing-unit;
    right: $base-spacing-unit;
    width: $base-spacing-unit;
    height: $base-spacing-unit;
    opacity: 0.5;
    outline: none;

    &:hover {
        opacity: 1;
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
