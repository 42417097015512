/*------------------------------------*\
    $ROYAL
\*------------------------------------*/

.royal-container {
    max-width: 880px;
}

.royal-grid {
    @include media-query(desk) {
        display: flex;
    }
}

.royal-title {
    @include font-size(20px, 24px);
    position: relative;
    padding: halve($base-spacing-unit) 40px halve($base-spacing-unit) $base-spacing-unit;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    color: $colour-ui-light;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 40px;
        border: 27px solid transparent;
        border-right-color: #8b724b;
        border-left-width: 0;
    }

    @include media-query(portable) {
        margin-left: -$base-spacing-unit;
        background-color: $colour-royal;
    }

    @include media-query(desk) {
        @include font-size(23px, 24px);
        width: 140px;
        margin-top: 0 - double($base-spacing-unit);
        padding: double($base-spacing-unit) 20px 55px;
        background-image: linear-gradient(to bottom, #8a5445, #bc8c31);

        &::after {
            top: auto;
            left: 0;
            border-width: 0 70px 35px 70px;
            border-color: transparent transparent $colour-royal transparent;
        }
    }
}

.royal-typography {
    @include font-size(15px, 20px);

    .lede {
        @include font-size(20px);
        margin-bottom: 20px;
        color: $colour-ui-light;
    }

    @include media-query(desk) {
        flex: 1;
        margin-left: 30px;
        margin-right: 30px;
    }
}

.royal-seal-wrapper {

    @include media-query(portable) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.royal-seal {
    width: 72px;

    @include media-query(portable) {
        margin-left: 0;
        margin-right: 20px;
    }

    @include media-query(desk) {
        margin-bottom:12px;
    }
}

.royal-seal-caption {
    @include font-size(11px, 14px);
    font-weight: $font-weight-semibold;
    text-transform: uppercase;

    @include media-query(desk) {
        @include font-size(9px, 11px);
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        max-width: 140px;
        color: #8b724b;
        text-align: center;
    }
}
