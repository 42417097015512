/*------------------------------------*\
    $FEATURE
\*------------------------------------*/

.feature-wrapper {
    @include media-query(palm) {
        margin-left: -6px;
        margin-right: -6px;
    }
}

.feature {
    display: flex;
    position: relative;
    background-color: $colour-ui-dark;
    color: $colour-ui-light;
    margin-bottom: 24px;
    min-height: 520px;

    &:last-child {
        margin-bottom: $base-spacing-unit;
    }

    @include media-query(lap) {
        margin-bottom: $base-spacing-unit;
    }
}

.feature-figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover';
    opacity: 0;
    transition: opacity $base-anim-speed;

    &.lazyloaded {
        opacity: 0.4;
    }
}

.feature-content {
    @include font-size(17px, 24px);
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: treble($base-spacing-unit) 36px ($base-spacing-unit * 1.5);

    @include media-query(desk) {
        padding: 90px 50px 45px;
    }
}

.feature-heading {
    @include font-size(32px, 38px);
    font-weight: $font-weight-semibold;
    margin-bottom: 20px;

    @media screen and (max-width:1100px)
	{
        @include font-size(24px, 32px);
    }
}

.feature-actions {
    display: flex;
    margin: auto -36px 0;

    @include media-query(desk) {
        margin-left: -50px;
        margin-right: -50px;
    }
    @media screen and (max-width:1100px)
	{
        margin-bottom:-45px;
        flex-direction:column;
    }
}

.feature-actions-item {
    flex: 1;

    @media screen and (max-width:1100px)
	{
        padding:0 5px 5px;
    }

    &:not(:last-child) {

        @media screen and (min-width:1101px)
    	{
            margin-right: halve($base-spacing-unit);
        }
    }
}

.feature-btn {
    white-space: nowrap;
    text-align: center;

    @media screen and (max-width:1100px)
	{
        text-align:left;
    }

    &:hover {
        color: $colour-ui-dark;
        background-color: $colour-tertiary;
    }
}
