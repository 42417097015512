/*------------------------------------*\
    $FOOTER
\*------------------------------------*/

.footer {
    @include font-size(14px, $base-spacing-unit);
}

.footer-heading {
    @include font-size(17px, $base-spacing-unit);
    color: $colour-ui-light;
}

/**
 * Worldwide Shipping block
 */

.footer-worldwide {
    @include font-size(19px, 23px);
    border-left: 3px solid $colour-primary;
    padding-left: 35px;
    max-width: 250px;
}

.footer-worldwide-title {
    @include font-size(20px, $base-spacing-unit);
    position: relative;
    padding-left: 38px;

    .icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
        color: $colour-primary;
        width: 38px;
        height: 38px;
    }
}

.footer-worldwide-link {
    @include font-size(15px, 18px);
    display: inline-block;
    position: relative;
    padding-left: 35px;
    color: $colour-ui-dark;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;

    .icon {
        color: $colour-primary;
        position: absolute;
        top: 0.6em;
        left: 0;
        transform: translateY(-50%);
        transition: transform $base-anim-speed ease-in-out;
    }

    .footer & {
        color: $colour-primary;
    }

    &:hover {
        color: $colour-primary;

        .footer & {
            color: $colour-ui-light;
        }

        .icon {
            transform: translate(5px, -50%);
        }
    }
}


.footer-head-office {
    @include font-size(13px, $base-spacing-unit);
    margin-bottom: 2px;
    color: #4e5952;

    @include media-query(palm) {
        display: none;
    }
}

.footer-copyright {
    color: #64736a;

    a {
        color: #abb2ae;

        &:hover {
            text-decoration: underline;
        }
    }
}

.footer-nav {
    column-gap: $base-spacing-unit;

    @include media-query(lap) {
        column-count: 3;
    }
}

.footer-nav-link {
    @include font-size(15px, $base-spacing-unit);
    color: $colour-ui-light;

    &:hover,
    &.is-current {
        color: $colour-tertiary;
    }
}

.footer-link {
    display: inline-block;
    color: currentColor;
    text-decoration: underline;

    &:hover {
        color: $colour-ui-light;
    }

    &.is-current {
        color: $colour-tertiary;
    }
}

.footer-location {
    position: relative;
    padding-left: 24px;
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    color: currentColor;

    .icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &:hover {
        color: $colour-ui-light;
    }
}
