/*------------------------------------*\
    $ERROR
\*------------------------------------*/

.error-band {
    position: relative;
    overflow: hidden;

    @include media-query(desk) {
        padding-top: 100px;
        padding-bottom: 100px - $base-spacing-unit;
    }
}

.error-code {
    top: 0;
    right: 3vw;
    position: absolute;
    color: #ebedec;
    font-size: 30vw;
    line-height: 1;
    font-weight: $font-weight-bold;;

    @include media-query(nav) {
        top: -12vw;
    }
}

.error-icon {
    width: 150px;
    height: 150px;
    margin-bottom: $base-spacing-unit;
}

.error-content {
    position: relative;
    max-width: 400px;
}
