/*------------------------------------*\
    $WIDTHS
\*------------------------------------*/

/**
 * Widths
 */

$breakpoint-widths: (
    '' : (
        'auto': auto
    ),
    'palm' : (
        '1/2': 1/2
    ),
    'portable' : (
        '1/3': 1/3,
        '1/2': 1/2,
        '2/3': 2/3
    ),
    'lap' : (
        'auto': auto,
        '1/3': 1/3,
        '1/2': 1/2,
        '2/5': 2/5,
        '1/5': 1/5
    ),
    'desk': (
        'auto': auto,
        '1/6': 1/6,
        '1/5': 1/5,
        '1/4': 1/4,
        '1/3': 1/3,
        '2/6': 2/6,
        '2/5': 2/5,
        '5/12': 5/12,
        '1/2': 1/2,
        '7/12': 7/12,
        '2/3': 2/3,
        '3/5': 3/5,
        '3/4': 3/4,
        '4/5': 4/5,
        '1/10': 1/10,
        '1/7': 1/7,
        '2/7': 2/7,
        '3/7': 3/7
    ),
    'wide': (
        '1/4': 1/4,
        '1/3': 1/3,
        '1/2': 1/2,
        '2/3': 2/3,
        '3/4': 3/4
    )
);

@each $breakpoint, $widths in $breakpoint-widths {
    @if ($breakpoint != '') {
        // Wrap a media query around it if we've included a breakpoint
        @include media-query($breakpoint) {
            @each $width, $fraction in $widths {
                [class~="#{$width}-#{$breakpoint}"] {
                    @include grid-width($fraction);

                    .grid-sml > & {
                        @include grid-width($fraction, 14px);
                    }

                    .grid-xs > & {
                        @include grid-width($fraction, 8px);
                    }

                    .grid-med > & {
                        @include grid-width($fraction, double($base-spacing-unit));
                    }
                }
            }
        }
    } @else {
        // If not, just spit out the classes
        @each $width, $fraction in $widths {
            [class~="#{$width}"] {
                @include grid-width($fraction);

                .grid-sml > & {
                    @include grid-width($fraction, 14px);
                }

                .grid-xs > & {
                    @include grid-width($fraction, 8px);
                }

                .grid-med > & {
                    @include grid-width($fraction, double($base-spacing-unit));
                }
            }
        }
    }
}
