/*------------------------------------*\
    $BAND
\*------------------------------------*/

/**
 * Full width, coloured wrappers. Designed to be used around .containers
 */

.band {
    padding: double($base-spacing-unit) $base-spacing-unit $base-spacing-unit;
}

.band-med {
    padding-top: $base-spacing-unit;
    padding-bottom: 1px;
}

.band-sml {
    padding-top: 18px;
    padding-bottom: 1px;
}

.band-xs {
    padding-top: 10px;
    padding-bottom: 10px;
}

.band-flush-top {
    padding-top: 0;
    border-top: none !important;
}


/**
 * Colour heirarchy
 */

.band-ui-light {
    & + & {
        border-top: 2px solid $colour-ui;
    }
}

.band-ui-dark {
    background-color: $colour-ui-dark;
}

.band-ui {
    background-color: #e8ebea; // $colour-ui;
}

.band-quaternary {
    background-color: $colour-quaternary;
    color: $colour-ui-light;

    & + & {
        border-top: 2px solid #294738;
    }
}

.band-quinary {
    background-color: $colour-quinary;
    color: #64736a;

    & + & {
        border-top: 2px solid #1c2e23;
    }
}

.band-royal {
    padding: 20px $base-spacing-unit 0;
    background-color: #8b724b;
    color: $colour-ui-light;
    display: none;

    ::selection {
        color: $colour-ui-dark;
        background-color: $colour-ui-light;
    }

    @include media-query(desk) {
        background-color: $colour-royal;
        color: #a27db2;
    }
}

.band-cover,
.band-cover-content {
    @include media-query(desk) {
        position: relative;
    }
}

.band-cover {
    @include media-query(desk) {
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
    }
}

.band-cover-figure {
    &#{&} {
        margin-bottom: 0;

        @include media-query(desk) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
