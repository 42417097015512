/*------------------------------------*\
    $SEARCH
\*------------------------------------*/

.search {
    position: relative;

    .icon {
        position: absolute;
        width: 15px;
        height: 15px;
        top: halve($base-spacing-unit);
        left: 0;
        transform: translateY(-50%);
        pointer-events: none;
    }

    @include media-query(nav-portable) {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    @include media-query(nav) {
        @media screen and (max-width: 1149px) {
            .icon {
                width: 16px;
                height: 16px;
                left: 50%;
                top: 2px;
                transform: translateX(-50%);
            }
        }
    }

    @media screen and (min-width: 1150px) {
        .icon {
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
}

.search-terms {
    @include font-size(18px, $base-spacing-unit);
    padding-left: 36px;
    border: none;
    background-color: transparent;
    width: 100%;
    appearance: none;
    border-radius: 0;

    &::placeholder {
        color: currentColor;
    }

    @include media-query(nav-portable) {
        border-bottom: 2px solid;
        padding-bottom: 12px;
    }

    @include media-query(nav) {
        @include font-size(12px, 20px);
        padding-left: 0;

        &::placeholder {
            color: #737373;
            text-transform: uppercase;
        }

        @media screen and (max-width: 1149px) {
            padding-top: 20px;
            text-align: center;
        }
    }

    @media screen and (min-width: 1150px) {
        padding-left: $base-spacing-unit;
    }
}

/*------------------------------------*\
    $TYPEAHEAD
\*------------------------------------*/

.tt-menu {
    position: absolute;
    top: calc(100% + 14px) !important;
    left: auto !important; right: 0;
    width: 100vw; max-width:320px;
    padding: 12px 0;
    border-radius: 4px;
    background-color: #ededed;
    box-shadow: 0 1px rgba(#000, .08);

    @include media-query(nav-portable) {
        display:none !important;
    }

    .tt-dataset.tt-dataset-search-result {
        overflow: hidden;
        overflow-y: auto;
        max-height: 370px;
    }

    &::before {
        content: "";
        position: absolute;
        bottom: 100%;
        right: 24px;
        border: 14px solid transparent;
        border-top-width: 0;
        border-bottom-color: #ededed;
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        height: 14px;
    }

    .subnav-item {
        color: $colour-ui-dark;

        &.no-result {
            padding:12px 18px;
        }
    }

    .subnav-link {
        display:flex;
        flex-direction:row;
        flex-wrap:nowrap;
        align-items:center;
        position:relative;
        z-index:1;
        padding:12px 18px;
        font-weight:400;
        text-transform:none;
        white-space:normal;

        a {
            position:absolute;
            top:0; left:0; right:0; bottom:0;
            z-index:2;
        }

        .thumb {
            display:block;
            width:auto; height:64px;
        }
        .info {
            padding-left:12px;

            .title {
                font-weight:600;
            }
        }
    }
}
