/*------------------------------------*\
    $TAGS
\*------------------------------------*/

.tags {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.tags-item {
    margin-left: 20px;
}

.tags-link {
    @include font-size(13px);
    padding: 2px 18px;
    display: inline-block;
    background-color: $colour-ui;
    color: #808080;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    border-radius: 3px;
    transition: background-color $base-anim-speed, color $base-anim-speed;

    &:hover {
        background-color: $colour-quaternary;
        color: $colour-ui-light;
    }
}
