/*------------------------------------*\
    $LOGO
\*------------------------------------*/

.logo-primary-wrapper {
    @include media-query(desk) {
        margin-bottom: double($base-spacing-unit);
    }
}

.logo-wrapper {
    @include media-query(desk) {
        &:last-child {
            margin-bottom: double($base-spacing-unit);
        }
    }
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px $base-spacing-unit;
    min-height: 105px;
    border: 3px solid #e6e6e6;
    margin-bottom: 10px;
}

.logo-figure {
    width:100%;
    object-fit: contain;
    font-family:'object-fit: contain';
}

.logo-content {
    color: #808080;

    h3 {
        color: $colour-ui-dark;
    }

    &::after {
        content: "";
        width: 65px;
        display: block;
        border-top: 3px solid $colour-primary;
        margin-bottom: $base-spacing-unit;
    }

    @include media-query(lap) {
        margin-top: $base-spacing-unit;
        margin-left: 50px;
        margin-right: 50px;
    }
}
