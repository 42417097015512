/*------------------------------------*\
    $NEWSLETTER
\*------------------------------------*/

.newsletter {
    @include font-size(16px, 22px);
    color: #77807b;
    background-color: $colour-ui;
}

.newsletter-graphical {
    position: relative;
    background-color: #333;

    @include media-query(desk) {
        padding-top: 145px;
    }
}

.newsletter-band {
    position: relative;

    @include media-query(desk) {
        padding-top: $base-spacing-unit * 1.5;
        padding-bottom: 1px;

        .newsletter-graphical & {
            background-color: rgba(#262626, .75);
        }
    }

    p {
        line-height:1.1;
    }
}

.newsletter-figure {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover';
    transition: opacity $base-anim-speed;

    &.lazyloaded {
        opacity: 0.15;
    }

    @include media-query(desk) {
        &.lazyloaded {
            opacity: 0.92;
        }
    }
}

.newsletter-heading {
    color: $colour-ui-dark;
    font-weight: $font-weight-semibold;

    .newsletter-graphical & {
        color: $colour-ui-light;
    }
}

.newsletter-field .field {
    margin-bottom: $base-spacing-unit;
}

.newsletter-control .field-control {
    @include font-size(16px, $base-spacing-unit);
    padding: halve($base-spacing-unit) 28px;

    &::placeholder {
        color: #77807b;
    }

    .newsletter-graphical & {
        border-color: transparent;
    }
}

.newsletter-categories-toggle {
    font-weight: $font-weight-semibold;
    text-decoration: underline;
    transition: color $base-anim-speed;

    &:hover {
        color: $colour-ui-dark;

        .newsletter-graphical & {
            color: $colour-ui-light;
        }
    }
}

.newsletter-check .check {
    color: $colour-ui-dark;
    cursor: pointer;

    a {
        color: currentColor;
        font-weight: $font-weight-semibold;

        &:hover {
            text-decoration: underline;
        }
    }

    .newsletter-graphical & {
        color: $colour-ui-light;
    }
}

.newsletter-check .check-body::before {
    border-color: $colour-primary;
}

.newsletter-btn {
    .newsletter-graphical & {
        &:hover {
            background-color: $colour-tertiary;
            color: $colour-ui-dark;
        }
    }

    @include media-query(desk) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}


/*------------------------------------*\
    $NEWSLETTER (PAGE BUILDER BLOCK)
\*------------------------------------*/

.pb-newsletter {
    transition: bottom $base-anim-speed;
    position: fixed;
    left: 12px;
    right: 12px;
    bottom: -100%;
    z-index: 19;
    padding: $base-spacing-unit;
    color: $colour-ui-light;
    text-align: center;
    background-color: $colour-primary;
    box-shadow: 0 0 48px 0 rgba(0,0,0, .24);

    @include media-query(desk) {
        left: 50%;
        right: auto;
        width: 100%;
        max-width: 700px;
        transform: translateX(-50%);
    }

    &.show {
        bottom: $base-spacing-unit * 2;

        @include media-query(desk) {
            bottom: $base-spacing-unit;
        }
    }
}

.pb-newsletter-btn-close {
    top:0; right:0;

    & > .modal-close-icon {
        background:none !important;
    }
}
