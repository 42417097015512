/*------------------------------------*\
    $EVENT
\*------------------------------------*/

.event-figure {
    @include media-query(portable) {
        margin-bottom: $base-spacing-unit;

        .lazyload-wrapper {
            margin-bottom: 0;
        }
    }

    @include media-query(desk) {
        position: relative;
        margin-top: double($base-spacing-unit);
        margin-bottom: double($base-spacing-unit);
    }

    &.info-below {

        .lazyload-wrapper {
            margin-bottom:0;
        }
        .event-details {
            position:static;
        }
    }
}

.event-details {
    @include font-size(16px, 20px);
    padding: 28px $base-spacing-unit;
    color: $colour-ui-light;
    background-color: $colour-quaternary;

    h4 {
        @include font-size(16px);
        margin-bottom: 0;
        color: $colour-tertiary;
    }

    &::after {
        content: "";
        display: block;
        margin-bottom: -28px;
    }

    @include media-query(desk) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-left: 48px;
        padding-right: 48px;
        background-color: rgba($colour-quaternary, .9);
    }

    @media screen and (min-width: 1050px) {
        padding-left: 98px;
        padding-right: 98px;
    }
}

.event-btn {
    @include font-size(13px, 12px);
    display: flex;
    align-items: center;
    padding: 0 30px 0 56px;
    height: 43px;
    width: 100%;
    position: relative;
    text-align: left;
    border-radius: 12px 12px 0 0;

    .icon {
        position: absolute;
        top: 50%;
        left: 38px;
        transform: translate(-50%, -50%);
    }

    &:hover {
        background-color: $colour-ui-dark;
        color: $colour-tertiary;
    }
}

.event-btn-ui {
    background-color: #ededed;
    color: $colour-ui-dark;
    border-radius: 0 0 12px 12px;
}
