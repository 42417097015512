/*------------------------------------*\
    $NAV
\*------------------------------------*/

.nav-wrapper {
    display: flex;
    flex-grow: 1;
    align-self: stretch;
}

.nav-back {
    position: absolute;
    width: $base-spacing-unit;
    height: $base-spacing-unit;
    top: 50%;
    left: 16px;
    color: $colour-ui-dark;
    transform: translateY(-50%);

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform:translate(-50%, -50%) rotate(0.5turn) ;
    }

    @include media-query(nav) {
        display: none;
    }
}

.nav-toggle {
    position: relative;
    margin: 30px 40px 0 0;
    // position: absolute;
    // right: 40px;
    // top: 50%;
    width: $base-spacing-unit;
    height: $base-spacing-unit;
    transform: translate(50%, -50%);
    background-color: rgba($colour-ui-light, .5);

    @include media-query(nav) {
        display: none;
    }
}

@keyframes burger-before {
    0% { transform: translateY(-6px); }
    50% { transform: none; }
    100% { transform: rotate(-45deg); }
}
@keyframes burger-before-rev {
    100% { transform: translateY(-6px); }
    50% { transform: none; }
    0% { transform: rotate(-45deg); }
}

@keyframes burger-after {
    0% { transform: translateY(6px); }
    50% { transform: none; }
    100% { transform: rotate(45deg); }
}
@keyframes burger-after-rev {
    100% { transform: translateY(6px); }
    50% { transform: none; }
    0% { transform: rotate(45deg); }
}

.nav-toggle-icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &,
    &::before,
    &::after {
        position: absolute;
        transition: background-color 0s halve($base-anim-speed);
        background-color: $base-text-colour;
        width: 22px;
        height: 3px;
    }

    &::before,
    &::after {
        content: "";
        top: 0;
        left: 0;
    }

    &::before {
        transform: translateY(-8px);
    }

    &::after {
        transform: translateY(8px);
    }

    .is-active > & {
        background-color: transparent;

        &::before {
            animation: burger-before $base-anim-speed forwards;
        }

        &::after {
            animation: burger-after $base-anim-speed forwards;
        }
    }

    .is-exiting > & {
        &::before {
            animation: burger-before-rev $base-anim-speed forwards;
        }

        &::after {
            animation: burger-after-rev $base-anim-speed forwards;
        }
    }
}

.nav {
    @include media-query(nav-portable) {
        position: fixed;
        left: 100%;
        top: 0;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        color: $colour-ui-light;
        padding: $base-spacing-unit $base-spacing-unit 1px;
        background-color: $colour-quaternary;
        overflow: auto;
        width: calc(100% - 70px);
        max-width: 300px;
    }

    @include media-query(nav) {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
    }
}

.nav-list {
    @include media-query(nav) {
        margin-bottom: 0;
        display: flex;
        margin-left: -20px;
        align-items: flex-end;

        &.header-controls {
            align-items: center;
        }
    }

    @media screen and (min-width: 850px) {
        margin-left: -$base-spacing-unit;
    }
}

.nav-item {
    @include media-query(nav-portable) {
        margin-bottom: 20px;
    }

    @include media-query(nav) {
        margin-left: 20px;
        // margin-left: $base-spacing-unit;

        .header-controls & {
            &:first-child {
                margin-left: 0;
            }

            @media screen and (max-width: 1149px) {
                max-width: 56px;
            }
        }
    }

    @media screen and (min-width: 850px) {
        margin-left: 30px;
    }
}

.nav-home {
    @include media-query(nav) {
        display: none;
    }
}

.nav-secondary {
    @include media-query(nav) {
        margin-right: 22px;
    }

    .nav-link:hover {
        color: $colour-tertiary;
    }
}


/**
 * Link styles and variations
 */

.nav-link {
    @include font-size(16px, $base-spacing-unit);
    text-decoration: none;
    color: currentColor;
    display: inline-block;

    @include media-query(nav) {
        @include font-size(14px, 1.2);
        display: block;

        .nav-secondary & {
            white-space: nowrap;
        }
    }
}

a.nav-link-ui:hover,
a.nav-link-ui.is-current {
    color: $colour-tertiary;
}

.nav-link-primary {
    font-weight: $font-weight-semibold;

    @include media-query(nav-portable) {
        @include font-size(18px, $base-spacing-unit);
    }

    @include media-query(nav) {
        font-weight: $font-weight-bold;
        text-transform: uppercase;

        &.is-current,
        &:hover {
            color: $colour-primary;
        }
    }
}

.nav-link-ui {
    @include font-size(12px, 20px);
    text-transform: uppercase;
    text-align: center;
    // font-weight: $font-weight-semibold;
    color: #737373;

    .icon {
        color: $colour-ui-light;
        width: 18px;
        height: 18px;
    }

    @include media-query(nav-portable) {
        vertical-align: top;
    }

    @media screen and (min-width: 1150px) {
        position: relative;
        padding-left: $base-spacing-unit;

        .icon {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }

    &.user-logged-in,
    &.user-logged-in:hover,
    &.user-logged-in .icon {
        color:$colour-tertiary !important;
    }
}

a.nav-link-ui:hover {
    color: $colour-ui-light;
}

.nav-link-ui-count {
    @include font-size(15px, 20px);
    font-weight: $font-weight-bold;
    color: $colour-ui-light;

    @media screen and (max-width: 1149px) {
        vertical-align: middle;
        margin-left: 7px;
    }

    @media screen and (min-width: 1150px) {
        margin-right: 7px;
    }
}


/**
 * CTA Actions
 */

.nav-actions-wrapper {
    display: flex;
    align-self: stretch;

    @include media-query(nav) {
        @media screen and (max-width: 1149px) {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    @media screen and (min-width: 1150px) {
        margin-left: 22px;
    }
}

.nav-actions,
.nav-actions-item {
    @include media-query(nav) {
        @media screen and (max-width: 1149px) {
            flex-grow: 1;
        }
    }
}

.nav-actions {
    margin-bottom: $base-spacing-unit * 1.5;

    @include media-query(nav-portable) {
        margin-left: -$base-spacing-unit;
        margin-right: -$base-spacing-unit;
    }

    @include media-query(nav) {
        display: flex;
        margin-bottom: 0;
    }
}

.nav-actions-item {
    display: flex;
}

.nav-actions-link {
    @include font-size(15px, 22px);
    position: relative;
    padding: 22px 24px;
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 100%;
        width: 100%;
        height: 3px;
        background-color: $colour-primary;
        transition: transform $base-anim-speed ease-out;
    }

    &:hover {
        background-color: $colour-ui-dark !important;
        color: $colour-ui-light !important;

        &::before {
            transform: translateY(100%);
            transition-timing-function: ease-in;
        }
    }

    &.is-current {
        color: $colour-tertiary;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 3px;
            background-color: currentColor;

        }
    }

    :nth-child(2) > & {
        background-color: $colour-secondary;

        &,
        &::before {
            background-color: $colour-secondary;
        }
    }

    :nth-child(3) > & {
        color: $colour-ui-dark;

        &,
        &::before {
            background-color: $colour-tertiary;
        }
    }

    @include media-query(nav-portable) {
        width: 100%;
    }

    @include media-query(nav) {
        @include font-size(14px, 18px);
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        // padding-top: 12px;
        // padding-bottom: 12px;
        min-height: 55px;
        font-weight: $font-weight-bold;
    }
}

#nav-item-basket,
#nav-item-shortlist {

    @media screen and (max-width:1400px) and (min-width:1140px)
    {
        .header-controls-text {
            display:none;
        }
    }
}
