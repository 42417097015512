/*------------------------------------*\
    $PAGINATION
\*------------------------------------*/

.pagination,
.pagination-container,
.pagination-list {
    display: flex;
}

.pagination {
    margin-bottom: double($base-spacing-unit);

    @include media-query(lap) {
        padding-left: $base-spacing-unit;
        padding-right: $base-spacing-unit;
        padding-bottom: halve($base-spacing-unit);
        border-bottom: 3px solid $colour-ui;
        margin-bottom: 35px;
    }
}

.band .pagination {
    margin-bottom: - ($base-spacing-unit + 2);
}

.pagination-container {
    align-items: center;

    @include media-query(lap) {
        justify-content: center;
    }
}

.pagination-prev,
.pagination-next {
    position: relative;
    display: block;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transition: color $base-anim-speed;
    }

    &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    @include media-query(palm) {
        width: 60px;
        height: 55px;
        background-color: #f0f2f1;
        text-indent: 100%;

        .icon {
            color: #b1b2b2;
        }
    }

    @include media-query(lap) {
        line-height: 51px;
        color: #262626;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 51px;
            height: 51px;
            background-color: #e8ebea;
            border-radius: 100%;
            transition: background-color $base-anim-speed;
        }

        &:hover {
            &::before {
                background-color: $colour-quaternary;
            }

            .icon {
                color: $colour-ui-light;
            }
        }
    }
}

.pagination-prev {
    .icon {
        transform: translate(-50%, -50%) rotate(0.5turn);
    }

    @include media-query(lap) {
        padding-right: double($base-spacing-unit);

        &::before {
            right: 0;
        }

        .icon {
            left: auto;
            right: 26px;
            transform: translate(50%, -50%) rotate(0.5turn);
        }
    }
}

.pagination-next {
    .icon {
        transform: translate(-50%, -50%);
    }

    @include media-query(lap) {
        padding-left: double($base-spacing-unit);

        &::before {
            left: 0;
        }

        .icon {
            left: 26px;
        }
    }
}

.pagination-list {
    @include font-size(19px);
    margin: 0 70px 0;

    @include media-query(palm) {
        display: none;
    }
}

.pagination-item {
    &:not(:last-child) {
        margin-right: 6px;
    }
}

.pagination-ellipsis {
    margin-left: 18px;
    margin-right: 24px !important;
}

.pagination-link {
    display: block;
    text-align: center;
    color: $colour-ui-dark;
    min-width: 26px;

    &:hover,
    &.is-current {
        color: $colour-primary;
    }

    &.is-current {
        position: relative;
        font-weight: $font-weight-bold;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -31px;
            right: 0;
            display: block;
            border-bottom: 3px solid $colour-primary;
        }
    }
}

.pagination-summary {
    margin: 0 auto;

    @include media-query(lap) {
        display: none;
    }
}

.pagination-grid {
    align-items:flex-end;

    .field {
        margin-bottom:0;
    }

    .wrapper-items-per-page {
        @media screen and (max-width: 800px) {
            display: none;
        }
    }
}
