//------------------------------------*\
//  $TOOLS-FUNCTIONS
//------------------------------------*/

// Maths functions

@function quarter($number) {
    @return $number / 4;
}

@function third($number) {
    @return $number / 3;
}

@function halve($number) {
    @return $number / 2;
}

@function double($number) {
    @return $number * 2;
}

@function treble($number) {
    @return $number * 3;
}

@function quadruple($number) {
    @return $number * 4;
}


// Convert any number to em equivalent
$browser-context: 16;

@function em($pixels, $context: $browser-context) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return $pixels / $context * 1em;
}


// Convert any px value into its rem equivalent

@function rem($number) {
    @return $number / $base-font-size * 1rem;
}


// Colour functions

@function tint($colour, $percentage) {
    @return mix($colour, #fff, $percentage);
}

@function shade($colour, $percentage) {
    @return mix($colour, #000, $percentage);
}
