/*------------------------------------*\
    $AJAX
\*------------------------------------*/

@keyframes ajax-rotate {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.ajax {
    position: relative;

    &::before,
    &::after {
        content: "";
        display: block;
        visibility: hidden;
        opacity: 0;
        z-index: 1;
        transition: opacity $base-anim-speed, visibility 0s $base-anim-speed;
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($base-background-colour, .75);

        .band-ui & {
            background-color: rgba($colour-ui, .75);
        }
    }

    &::after {
        position: absolute;
        border: 3px solid transparent;
        border-top-color: $colour-primary;
        border-bottom-color: $colour-primary;
        border-radius: 99px;
        width: 50px;
        height: 50px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: ajax-rotate 0.6s infinite linear;
    }

    &.is-loading {
        &::before,
        &::after {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
        }
    }
}
