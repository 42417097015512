/*------------------------------------*\
    $SHARE
\*------------------------------------*/

.share {
    padding: halve($base-spacing-unit) 50px;
    margin-bottom: $base-spacing-unit;
    background-color: $colour-ui;

    @include media-query(palm) {
        margin-left: -$base-spacing-unit;
        margin-right: -$base-spacing-unit;
    }

    @include media-query(lap) {
        border-radius: 99px;
        display: inline-flex;
        align-items: center;
        margin-top: $base-spacing-unit;
    }
}

.share-heading,
.share-list {
    margin-bottom: 0;
}

.share-heading {
    position: relative;

    .icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        @include media-query(palm) {
            display: none;
        }
    }

    @include media-query(palm) {
        margin-bottom: halve($base-spacing-unit);
    }

    @include media-query(lap) {
        margin-right: $base-spacing-unit;
        margin-top: -3px; padding-right: 40px;
    }
}

.share-list {
    display: flex;
    margin-left: -$base-spacing-unit;
}

.share-item {
    margin-left: $base-spacing-unit;
}

.share-link {
    @include font-size(13px);
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: $colour-ui-dark;

    &:hover {
        color: $colour-primary;
    }
}
