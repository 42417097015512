/*!
 * Architecture here based on inuitcss, by @csswizardry
 * github.com/inuitcss | inuitcss.com
 */
//------------------------------------*\
//  $SETTINGS
//------------------------------------*/
// Global variables, config switches
@import
    "settings/base",
    "settings/breakpoints";


//------------------------------------*\
//  $TOOLS
//------------------------------------*/
// Default mixins and functions
@import
    "tools/functions",
    "tools/mixins",
    "tools/responsive";


//------------------------------------*\
//  $GENERIC
//------------------------------------*/
// Ground-zero styles (Normalize.css, resets, box-sizing)
@import
    "generic/resetalize",
    "generic/shared";


//------------------------------------*\
//  $BASE
//------------------------------------*/
// Unclassed HTML element selectors
@import
    "base/page",
    "base/figcaptions",
    "base/headings",
    "base/images",
    "base/links";


//------------------------------------*\
//  $OBJECTS
//------------------------------------*/
// Cosmetic-free design patterns
@import
    "objects/container",
    "objects/figure",
    "objects/grid",
    "objects/link-bare",
    "objects/icon",
    "objects/split";


//------------------------------------*\
//  $COMPONENTS
//------------------------------------*/
// Designed components, chunks of UI
@import
    "components/ajax",
    "components/alert",
    "components/arrow",
    "components/band",
    "components/bleed",
    "components/box",
    "components/branches",
    "components/brand",
    "components/breadcrumbs",
    "components/btn",
    "components/category",
    "components/carousel",
    "components/contact",
    "components/check",
    "components/ctas",
    "components/data",
    "components/error",
    "components/event",
    "components/feature",
    "components/field",
    "components/filters",
    "components/footer",
    "components/fragment",
    "components/gallery",
    "components/grounds-care",
    "components/header",
    "components/hero",
    "components/home",
    "components/landmark",
    "components/language",
    "components/lazyload",
    "components/lede",
    "components/lock",
    "components/login",
    "components/logo",
    "components/machinery-brand",
    "components/main",
    "components/modal",
    "components/nav",
    "components/newsletter",
    "components/notice",
    "components/page",
    "components/pagination",
    "components/post",
    "components/product",
    "components/quote",
    "components/recaptcha",
    "components/royal",
    "components/search-result",
    "components/search",
    "components/share",
    "components/shortlist",
    "components/sidenav",
    "components/skip",
    "components/slick",
    "components/slider",
    "components/social",
    "components/subnav",
    "components/table",
    "components/tags",
    "components/team",
    "components/toggle",
    "components/typography",
    "components/vacancy",
    "components/video",
    "components/widget",
    "components/woocommerce",
    "components/woo-category-filters",
    "components/woo-cat-nav";


//------------------------------------*\
//  $TRUMPS
//------------------------------------*/
// Helpers, utilities and overrides
@import
    "trumps/accessibility",
    "trumps/adjacent",
    "trumps/align",
    "trumps/clearfix",
    "trumps/headings",
    "trumps/hide",
    "trumps/img-hide",
    "trumps/print",
    "trumps/widths";

//------------------------------------*\
//  $VENDOR
//------------------------------------*/
@import
    "vendor/choices";


// Please do not add any styles to the bottom of this file - add
// edits to the relevant partial or create a new partial
