/*------------------------------------*\
    $TYPOGRAPHY
\*------------------------------------*/

.mce-content-body,
.woocommerce-Tabs-panel,
.typography {
    a:not([class]) {
        font-weight: $font-weight-semibold;
        text-decoration: underline;
    }

    ul:not([class]),
    ol {
        // color: #808080;

        > li {
            position: relative;

            &::before {
                position: absolute;
                left: -$base-spacing-unit;
                top: 1em * (29 / 17 / 2); //50%;
                transform: translate(-50%, -50%);
            }

            &:not(:last-child) {
                margin-bottom: halve($base-spacing-unit);
            }
        }

        @include media-query(desk) {
            margin-bottom: double($base-spacing-unit);
        }
    }

    ul:not([class]) {
        border-left: 1px solid #dbdbdb;
        padding: 4px 0 4px $base-spacing-unit;

        > li {
            position: relative;

            &::before {
                content: "";
                background-color: $colour-secondary;
                width: 10px;
                height: 10px;
                border-radius: 99px;
            }

            &:not(:last-child) {
                margin-bottom: halve($base-spacing-unit);
            }
        }
    }

    ol {
        padding-left: $base-spacing-unit + 1px;
        counter-reset: numbers;

        > li {
            counter-increment: numbers;

            &::before {
                content: counter(numbers);
                color: $colour-secondary;
                font-style: italic;
                font-weight: $font-weight-bold;
            }
        }
    }

    h5 {
        color: $colour-secondary;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }

    table td {
        font-size:1rem;
    }
}

.typography-lrg {
    @include font-size(17px, 29px);
}

.typography-light {
    &,
    h1,h2,h3,h4,h5,h6,
    a {
        color: $colour-ui-light;
    }
}

.typography-features {
    @include font-size(16px);
    position: relative;
    font-weight: $font-weight-semibold;
    color: #4c4c4c;

    > li {
        display: block;
        break-inside: avoid;
        padding: 12px 36px;
        border: 2px solid #dbdbdb;
        border-width: 1px 2px 0;

        &:first-child {
            border-top-width: 2px;
        }

        &:last-child {
            border-bottom-width: 2px;
        }
    }

    @include media-query(lap) {
        column-count: 2;
        column-gap: $base-spacing-unit;

        &::before,
        &::after {
            content: "";
            position: absolute;
            z-index: 1;
            width: calc(50% - #{halve($base-spacing-unit)});
            border-top: 1px solid #dbdbdb;
        }

        &::before {
            bottom: 0;
            left: 0;
            border-top-width: 2px;
        }

        &::after {
            top: 1px;
            right: 0;
        }
    }
}


.typography-features-secondary {
    margin-bottom: 0;

    > li {
        @include font-size(20px);
        margin-bottom: $base-spacing-unit;

        &::after {
            content: "";
            display: block;
            width: 68px;
            margin-top: halve($base-spacing-unit);
            border-top: 3px solid $colour-primary;
        }
    }

    @include media-query(lap) {
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        justify-content:space-between;
        // column-count: 2;
        // column-gap: $base-spacing-unit;

        & > li {
            width:calc(50% - 15px);
        }
    }
}

.typography-icons-wrapper {
    max-width: 650px + 98px;
}

.typography-icons {
    > li {
        position: relative;
        padding-left: 100px;
        color: #808080;

        .icon {
            position: absolute;
            top: 50px;
            left: 50px;
            width: 50px;
            height: 50px;
            color: $colour-ui-dark;
            transform: translate(-50%, -50%);
        }
    }

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: halve($base-spacing-unit);
        color: $colour-ui-dark;
    }

    @include media-query(desk) {
        @include grid;

        > li {
            @include grid-item($base-spacing-unit, 1/2);
        }
    }
}

.typography-compact {
    > :not(:last-child) {
        --typography-margin: #{halve($base-spacing-unit)};
    }
}

.typography-compact-xs {
    > :not(:last-child) {
        --typography-margin: #{quarter($base-spacing-unit)};
    }
}

.typography-columns {
    @include media-query(lap) {
        position: relative;
        column-count: 2;
        column-gap: double($base-spacing-unit);
        margin-bottom: $base-spacing-unit;
    }
}

.typography-landmark {
    @include media-query(desk) {
        > :last-child {
            margin-bottom: double($base-spacing-unit);
        }
    }
}
