/*------------------------------------*\
    $SKIP
\*------------------------------------*/

.skip {
    position: absolute;
    top: 0;
    z-index: 10;
    left: -999em;

    &:focus {
        left: 0;
    }
}
