//------------------------------------*\
//  $TOOLS-MIXINS
//------------------------------------*/

// Font sizing mixin - calculates font-size in rems with px fallback and
// line-height ratio based on default baseline height

@mixin font-size($font-size, $line-height: auto) {
    font-size: $font-size;
    font-size: ($font-size / $base-font-size) * 1rem;

    @if $line-height == auto {
        line-height: ceil($font-size / $base-line-height) * ($base-line-height / $font-size);
    }

    @else {
        @if (type-of($line-height) == number and unit($line-height) == px) {
            line-height: $line-height / $font-size;
        }

        @else if (type-of($line-height) == number or $line-height == inherit or $line-height == normal) {
            line-height: $line-height;
        }

        @else if ($line-height == none or $line-height == false) {
        }

        @else {
            @warn "‘#{$line-height}’ is not a valid value for `line-height`.";
        }
    }
}


// Responsive fonts

@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
    $responsive-unitless: $responsive / ($responsive - $responsive + 1);
    $dimension: if(unit($responsive) == 'vh', 'height', 'width');
    $min-breakpoint: $min / $responsive-unitless * 100;

    @media (max-#{$dimension}: #{$min-breakpoint}) {
        font-size: $min;
    }

    @if $max {
        $max-breakpoint: $max / $responsive-unitless * 100;

        @media (min-#{$dimension}: #{$max-breakpoint}) {
            font-size: $max;
        }
    }

    @if $fallback {
        font-size: $fallback;
    }

    font-size: $responsive;
}


// Combined hover/focus states

@mixin hocus {
    &:hover,
    &:focus {
        @content;
    }
}


// Remove default styles from <input> or <button> elements

@mixin button-bare {
    background: none;
    border: none;
    font: inherit;
    text-align: left;
    margin: 0;
    padding: 0;
}


// Screen-readable, visually hidden elements

@mixin visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}


// Hide images visually but preserve intrinsic width and height
// for improved page speed

@mixin img-hide {
    position: absolute;
    left: -9999px;
    max-width: none;
    opacity: 0;
}


// Micro clearfix

@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}
