/*------------------------------------*\
    $CAROUSEL
\*------------------------------------*/

.carousel {
    position: relative;
}

.carousel-wrapper {
    margin-left: auto;
    margin-right: auto;

    @include media-query(palm) {
        max-width: 250px;
        width: 100%;
    }

    @include media-query(lap) {
        width: percentage(5/6);
    }
}

.carousel-slider {
    margin: -10px -5px $base-spacing-unit;

    &#{&} {
        position: static;
    }

    &:not(.slick-initialized) {
        @include media-query(palm) {
            .slick-slide:nth-child(-n + 3) {
                display: block !important;
            }
        }

        @include media-query(lap-only) {
            .slick-slide:nth-child(-n + 3) {
                display: flex !important;
                width: percentage(1/3);
            }
        }

        @include media-query(desk) {
            .slick-slide:nth-child(-n + 4) {
                display: flex !important;
                width: percentage(1/4);
            }
        }
    }

    @include media-query(palm) {
        .slick-arrow {
            width: 22px;
            height: calc(100% - #{$base-spacing-unit * 8});
            background-color: #e0e0e0;
            color: transparent !important;
            transition: background-color $base-anim-speed, opacity $base-anim-speed, transform $base-anim-speed ease-in-out, visibility 0s;

            &.slick-disabled {
                opacity: 0;
                visibility: hidden;
                transition-delay: 0s, 0s, 0s, $base-anim-speed;
            }
        }
    }

    @include media-query(lap) {
        .slick-arrow {
            height: 105px;
        }
    }
}

@include media-query(lap) {
    // .carousel-slider,
    // .carousel-slider .slick-list,
    .carousel-slider:not(.slick-initialized),
    .carousel-slider .slick-track,
    .carousel-slider .slick-track > .slick-slide,
    .carousel-slider .slick-track > .slick-slide > div,
    .carousel-slide {
        display: flex;
    }

    .carousel-slider .slick-track > .slick-slide > div {
        flex: 1;
    }
}

.carousel-slide {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;

    .slick-initialized & {
        display: flex !important;
    }
}

.carousel-link {
    display: block;
    align-items: center;
    flex: 1;
    padding: 25px $base-spacing-unit;
    border: 2px solid #f3f3f3;
    transition: border $base-anim-speed;

    &:hover {
        border-color: $colour-secondary;
    }

    @include media-query(lap) {
        display: flex;
    }
}

.carousel-figure {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:100%;
    object-fit: contain;
    font-family:'object-fit: contain';
}
