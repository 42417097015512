/*------------------------------------*\
    $SPLIT
\*------------------------------------*/

.split {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.split-title {
    flex: 1;
}
