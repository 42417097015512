/*------------------------------------*\
    $LANGUAGE
\*------------------------------------*/

.language {
    position: relative;
    display: inline-block;
    z-index: 1;
    margin-bottom: 16px;
}

.language-toggle {
    display: block;
    background-color: $colour-ui-light;
    color: $colour-ui-dark;
    font-weight: $font-weight-semibold;
    padding-right: 40px;
    padding-top: 16px;
    padding-bottom: 16px;
    min-width: 160px;
    transition: background-color $base-anim-speed, color $base-anim-speed;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 16px;
        border: 4px solid transparent;
        border-top-color: currentColor;
        border-bottom-width: 0;
        transform: translate(50%, -50%);
        transition: transform $base-anim-speed ease-in-out;
    }

    &.is-active {
        color: $colour-ui-dark;

        &::after {
            transform: translate(50%, -50%) rotate(0.5turn);
        }
    }

    .modal & {
        border: 1px solid #e6e6e6;
    }
}

.language-list {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #e6e6e6;
    border: 1px solid #aeb8b0;
    min-width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    min-width: 160px;
    visibility: hidden;
    opacity: 0;
    transition: opacity $base-anim-speed, visibility 0s $base-anim-speed;

    &.is-active {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
    }

    .modal & {
        border-color: #e6e6e6;
    }
}

.language-toggle,
.language-link {
    font-weight: $font-weight-semibold;
    display: block;
    position: relative;
    padding-left: 52px;
    padding-right: 40px;
    white-space: nowrap;
}

.language-link {
    color: $colour-ui-dark;
    padding-top: 6px;
    padding-bottom: 6px;

    &:hover {
        text-decoration: underline;
    }

    &.is-active {
        color: $colour-primary;
    }
}

.language-flag {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    border-radius: 5px;
}
