/*------------------------------------*\
    $PRODUCT
\*------------------------------------*/

/**
 * Product Listing
 */

.product-band {
    position: relative;
}

.product-slider {
    margin-left: -6px;
    margin-right: -6px;
    display: block;

    @include media-query(desk) {
        .slick-track {
            display: flex;
            justify-content: center;
            margin: 0 auto;
        }
    }
    @include media-query(palm) {
        .slick-list {
            overflow: visible;
            width: 100%;
        }
    }

    @include media-query(lap) {
        margin-left: 0 - halve($base-spacing-unit);
        margin-right: 0 - halve($base-spacing-unit);

        .slick-track {
            display: flex;
        }
    }
}

.product {
    display: flex;
    flex-direction: column;
    background-color: $colour-ui;
    color: #727a76;
    margin-bottom: $base-spacing-unit * 1.5;
    padding: $base-spacing-unit 40px;

    @include media-query(desk) {
        margin-bottom: double($base-spacing-unit);
    }

    .product-slider:not(.slick-initialized) & {
        @include media-query(lap) {
            &:nth-child(-n + 2) {
                display: flex !important;
                width: calc(50% - #{$base-spacing-unit});
            }
        }

        @include media-query(desk) {
            &:nth-child(-n + 3) {
                display: flex !important;
                width: calc(#{percentage(1/3)} - #{$base-spacing-unit});
            }
        }

        @include media-query(wide) {
            &:nth-child(-n + 4) {
                display: flex !important;
                width: calc(25% - #{$base-spacing-unit});
            }
        }
    }
}

.product-grounds-care {
    background-color: $colour-ui-light;
}

.product-slide {
    margin: 0 6px $base-spacing-unit;

    @include media-query(lap) {
        margin-left: halve($base-spacing-unit);
        margin-right: halve($base-spacing-unit);
    }
}

.product-thumb {
    display: block;
    margin: (0 - $base-spacing-unit) -40px $base-spacing-unit;
    position: relative;
    background-color: $colour-ui-dark;

    &::before {
        content: "";
        display: block;
        padding-top: percentage(2/3);
    }
}

.product-figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity $base-anim-speed;;

    &.lazyloaded {
        opacity: 1;
    }
}

.product-tag {
    @include font-size(12px, 24px);
    position: absolute;
    right: 0;
    bottom: 12px;
    padding: 5px 18px;
    min-width:96px;
    font-weight: $font-weight-bold;
    text-align:center;
    text-transform: uppercase;
    background-color: $colour-primary;
    color: $colour-ui-light;
    border-radius: 3px 0 0 3px;

    & + .product-tag {
        bottom:48px;
    }
}

.product-title {
    @include font-size(20px, 24px);
    color: $colour-ui-dark;
    margin-bottom: halve($base-spacing-unit);
    font-weight: $font-weight-semibold;

    @include media-query(desk) {
        @include font-size(17px, 24px);
    }
}

.product-footer {
    background-color: #dce0df;
    margin: auto -40px (0 - $base-spacing-unit);
    padding: 16px $base-spacing-unit;

    .product-grounds-care & {
        background-color: transparent;
        padding-top: 0;
    }
}

.product-price {
    text-align: center;
    margin-bottom: 12px;
}

.product-vat {
    @include font-size(12px, 20px);
    display: inline-block;
    margin-top: 6px;
    color: $colour-ui-dark;

    .product-table & {
        @include font-size(10px, 18px);
        margin-top: 0;
        font-weight: $font-weight-normal;
    }
}

.product-btn {
    @include font-size(18px, 24px);

    small {
        @include font-size(14px, 18px);
        font-weight: $font-weight-normal;
        text-transform: none;
    }
}

.product-actions {
    margin-bottom: 0;
}

.product-actions-item {
    &:not(:last-child) {
        // margin-bottom: 8px;
    }
}

.product-actions-link {
    @include font-size(14px, 18px);
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    color: $colour-ui-dark;
    padding-left: 32px;

    .icon {
        position: absolute;
        top: (18/14/2) * 1em;
        left: 0;
        color: $colour-primary;
        transform: translateY(-50%);
        transition: transform $base-anim-speed ease-in-out;
    }

    &:hover {
        color: $colour-primary;

        .icon {
            transform: translate(5px, -50%);
        }
    }
}


/**
 * Product Detail
 */

.product-detail-filters {
    @include media-query(portable) {
        border-top: none !important;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.product-detail-depot-wrapper {
    margin-top: 15px;
}

.product-detail-depot {
    font-weight: $font-weight-semibold;

    a:hover {
        text-decoration: underline;
    }

    .product-detail-footer & {
        @include font-size(18px);
    }
}

.product-detail-wrapper {
    padding-top: 0;

    @include media-query(portable) {
        border-top: none !important;
    }

    @include media-query(desk) {
        padding-top: $base-spacing-unit;
    }
}

.product-detail-info-wrapper {
    display: flex;
    flex-direction: column;
}

.product-detail-info {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-bottom: $base-spacing-unit;
    padding: 0 $base-spacing-unit 0;
    background-color: $colour-ui;

    @include media-query(palm) {
        margin-left: -$base-spacing-unit;
        margin-right: -$base-spacing-unit;
    }

    @include media-query(lap) {
        border-radius: 3px;
    }
}

.product-detail-footer {
    position: relative;
    background-color: #dce0df;
    margin-top: auto;
    margin-left: -$base-spacing-unit;
    margin-right: -$base-spacing-unit;
    padding: 25px $base-spacing-unit 1px;

    .btn-plain {
        color: $colour-ui-dark;

        &:hover {
            color: $colour-primary;
        }
    }

    p:not(.product-detail-price) {
        margin-bottom: 15px;
    }

    @include media-query(lap) {
        border-radius: 0 0 3px 3px;
    }

    // @include media-query(desk) {
    //     // padding-top: 46px;
    // }
}

.product-detail-price {
    @include font-size(22px);
    position: absolute;
    margin: 0;
    padding: 13px 32px;
    bottom: 100%;
    left: 50%;
    white-space: nowrap;
    transform: translate(-50%, 50%);
    background-color: $colour-ui-light;
    border-radius: 99px;
    border: 1px solid #c2c7c6;
    color: $colour-ui-dark;

    small {
        @include font-size(16px);
        color: #727a76;
    }
}

.product-detail-share {
    margin-bottom: 12px;

    .social {
        margin-bottom: 12px;
        width: 24px;
        height: 24px;
        color: rgba($colour-ui-dark, .3);

        &:hover {
            color: $colour-ui-dark;
        }
    }
}

.product-detail-share-label {
    position: relative;
    padding-right: 32px;
    margin-bottom: 12px;

    .icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
}

.product-detail-typography {
    max-width: 945px;

    p {
        text-transform: uppercase;
        font-weight: $font-weight-semibold;
    }

    @include media-query(lap) {
        margin-top: $base-spacing-unit;
    }

    @media print {
        &#{&} {
            @include font-size(14px, 1.5);
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
}

.product-detail-request {
    position: fixed;
    bottom: 47px;
    right: 0;
    z-index: 5;
    font-weight: $font-weight-bold;

    @include media-query(lap) {
        right: $base-spacing-unit;
        padding-left: 53px;
        padding-right: 53px;
    }

    @include media-query(nav) {
        bottom: 0;
    }
}


/**
 * Product Table
 */

.product-table {
    min-width: 1000px;
}

.product-table-sort a {
    position: relative;
    display: inline-block;
    padding-right: 12px;
    color: $colour-ui-dark;

    &::after,
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 6px;
        height: 6px;
        border-right: 2px solid;
        border-bottom: 2px solid;
        transform: translateY(-50%) rotate(-135deg);
        margin-top: -2px;
        // display: none;
        opacity: 0.25;
        transition: opacity $base-anim-speed;
    }

    &::after {
        margin-top: 2px;
        transform: translateY(-50%) rotate(45deg);
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }

    &.is-active {
        &::before {
            opacity: 1;
        }

        &:hover {
            &::after {
                opacity: 1;
            }

            &::before {
                opacity: 0.25;
            }
        }
    }

    &.order-desc {
        &::after {
            opacity: 1;
        }

        &::before {
            opacity: 0.25;
        }

        &:hover {
            &::before,
            &::after {
                opacity: 0.25;
            }
        }
    }
}

table {
    width: 100%;
    border: 1px solid #f2f5f4;
    position: relative;

    td, th {
        padding: 13px 14px 13px 0;
        border: none;

        &:first-child {
            padding-left: 14px;
        }

        &.full-padding {
            padding:12px 18px !important;
        }
    }

    th {
        @include font-size(12px);
        text-transform: uppercase;
        text-align: left;
    }

    td {
        // @include font-size(13px);
        position: relative;
        z-index: 1;
        color: #808080;
        font-weight: $font-weight-semibold;
        line-height: 1.3;
        transition: opacity $base-anim-speed;

        .ajax.is-loading & {
            opacity: 0.25;
        }
    }

    tbody {
        tr:nth-of-type(odd) td {
            background-color: #f2f5f4;
        }
    }

    .product-tag {
        @include font-size(10px, 20px);
        display: inline-block;
        line-height: 1.2;
        text-align: center;
        position: static;
        border-radius: 0 3px 3px 0;
        padding: 4px 14px;
        width: 100%;
        min-width: 0;
    }

    .lazyload-wrapper {
        display: block;
        margin-top: -7px;
        margin-bottom: -7px;
        min-width: 70px;

        &,
        img {
            border-radius: 3px;
        }
    }
}

.product-table-tags {
    td, th {
        &:first-child {
            padding-left: 0;
        }
    }
}

.product-table-title {
    color: $colour-ui-dark;

    &:hover {
        color: $colour-primary;
    }
}

.remove-product {
    position:absolute;
    top:50%; right:-12px;
    z-index:2;
    width:24px; height:24px;
    background-color:$colour-ui-dark;
    border-radius:100%;
    transform:translateY(-50%);
    transition:color $base-anim-speed, background-color $base-anim-speed;

    svg {
        display:block;
        padding:8px;
        width:8px; height:8px;
        fill:#FFF;
        box-sizing:content-box;
    }

    @media screen and (min-width:769px)
    {
        &:hover,
        &:focus {
            background-color:#d91e18;
        }
    }
}

.product-paidfor-description {
    color: #d91e18;
}

.product-paidfor {
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    left: 40px;
    width: 16px;
    height: 16px;
    background-color: #d91e18;
    border: 2px solid #e6e6e6;
    border-radius: 100%;

    &.is-inline {
        border-color: $base-background-colour;
        position: static;
        display: inline-block;
        vertical-align: middle;
        transform: none;
        margin-left: 4px;
        margin-right: 4px;
    }

    .product-table & {
        left: -3px;
        bottom: -3px;
        transform: none;
        width: 12px;
        height: 12px;
    }
}

.product-table-internal {
    color: shade($colour-tertiary, 80%);
}

.product-title .link-bare:visited,
.product-table-title:visited {
    color: $colour-primary;

    &:hover {
        color: $colour-primary;
    }
}

.product-info {
    @include grid(16px, $base-spacing-unit - 12px);
    line-height: 1.2;
}

.product-info-item {
    @include grid-item(16px, 1/2);
    margin-bottom: 12px;
    text-align: center;
}

.product-info-title {
    display: block;
    font-weight: $font-weight-semibold;
    color: $colour-ui-dark;
}
