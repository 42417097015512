/*------------------------------------*\
    $BUTTONS
\*------------------------------------*/

.btn {
    @include font-size(16px, 24px);
    display: inline-block;
    vertical-align: middle;
    font: inherit;
    text-align: center;
    cursor: pointer;
    margin: 0;
    padding: halve($base-spacing-unit) $base-spacing-unit;
    background-color: $colour-primary;
    color: $colour-ui-light;
    border: none;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
    transition: color $base-anim-speed, background-color $base-anim-speed;

    &,
    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:hover {
        background-color: $colour-quaternary;
        color: $colour-ui-light;
    }
}


/**
 * Icons
 */

.btn-icon,
.btn-icon-left {
    position: relative;
    text-align: left;

    .icon {
        position: absolute;
        top: 50%;
    }
}

.btn-icon {
    padding-right: 60px;

    .icon {
        right: 35px;
        transform: translate(50%, -50%);
        transition: transform $base-anim-speed;
    }

    &:hover {
        .icon {
            transform: translate(80%, -50%);
        }
    }
}

.btn-icon-left {
    padding-left: 60px;

    .icon {
        left: 35px;
        transform: translate(-50%, -50%);
    }
}


/**
 * Colour heirarchy and styles
 */

.btn-secondary {
    background-color: $colour-secondary;
    color: $colour-tertiary;
}

.btn-tertiary {
    background-color: $colour-tertiary;
    color: $colour-ui-dark;
}

.btn-plain {
    color: $colour-primary;

    &,
    &.btn-sml {
        padding: 0;
    }

    &,
    &:hover {
        background-color: transparent;
    }

    &:hover {
        color: $colour-ui-dark;
    }

    &.btn-icon {
        padding-right: 24px;

        .icon {
            right: 8px;
        }
    }

    &.btn-icon-left {
        padding-left: 24px;

        .icon {
            left: 8px;
        }
    }

    .hero &,
    .post & {
        color: $colour-ui-light;
    }

    & + .btn-icon-left {
        margin-left:12px;
    }
}

.btn-ui {
    background-color: #85938b;
}

.btn-soft {
    border-radius: 99px;
}

.btn-ui-light {
    background-color: $colour-ui-light;
    color: $colour-primary;

    // &:hover {
    //     color: $colour-ui-light;
    // }
}


/**
 * Size Modifiers
 */

.btn-full {
    width: 100%;
}

.btn-sml {
    @include font-size(13px, 18px);
    padding: 11px 25px;

    &.btn-icon-left {
        padding-left: 55px;

        .icon {
            left: 32px;
        }
    }
}

.btn-med {
    @include media-query(lap) {
        @include font-size(19px);
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.btn-half {

    &:first-child {
        padding-right:20px;
        border-radius:99px 0 0 99px;
    }
    & + .btn-half {
        padding-left:20px;
        border-left:1px solid rgba(#FFF, .12);
        border-radius:0 99px 99px 0;
    }
}
