/*------------------------------------*\
    $ALIGN
\*------------------------------------*/

/**
 * Alignment utility classes
 */

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}


/**
 * Alignment based on a breakpoint
 */

@include media-query(desk) {
    .align-center-desk {
        text-align: center;
    }

    .align-right-desk {
        text-align: right;
    }
}

@media print {
    .align-left-print {
        text-align: left;
    }
}
