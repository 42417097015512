/*------------------------------------*\
    $SLICK
\*------------------------------------*/

/* Slider */
.slick-slider {
    // position: relative;

    // display: block;
    box-sizing: border-box;

    // -webkit-user-select: none;
    //    -moz-user-select: none;
    //     -ms-user-select: none;
    //         user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    &:not(.slick-initialized) {
        @include clearfix;
    }
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    // margin-left: auto;
    // margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    float: left;

    // height: 100%;
    // min-height: 1px;

    .slick-slider:not(.slick-initialized) & {
        float: none;

        &:not(:first-child) {
            display: none;
        }
    }

    // &:first-child {
    //     display: block;
    // }
}
[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

// .slick-initialized .slick-slide {
//     display: block;
// }

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

// .slick-dots {
//     display: flex;
//     justify-content: center;
//
//     button {
//         position: relative;
//         display: block;
//         width: 28px;
//         height: 28px;
//         text-indent: 100%;
//         overflow: hidden;
//
//         &::before {
//             content: "";
//             width: 16px;
//             height: 16px;
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             background-color: $colour-tertiary;
//             border-radius: 8px;
//             opacity: 0.5;
//             transform: translate(-50%, -50%);
//             transition: opacity $base-anim-speed;
//         }
//     }
//
//     .slick-active button::before {
//         opacity: 1;
//     }
// }

.slick-arrow {
    position: absolute;
    top: 50%;
    background-color: #efefef;
    color: #b0b0b0;
    height: 230px;
    width: 60px;
    z-index: 1;
    transition: color $base-anim-speed, transform $base-anim-speed ease-in-out;

    &:hover {
        color: $colour-ui-dark;
        transform: translateY(-50%);
    }

    .icon {
        position: absolute;
        top: 50%;
        width: 15px;
        height: 25px;
    }
}

.slick-next {
    right: 0;
    transform: translate(5px, -50%);

    .icon {
        left: 30px;
        transform: translate(-50%, -50%);
    }
}

.slick-prev {
    left: 0;
    transform: translate(-5px, -50%);

    .icon {
        right: 30px;
        transform: translate(50%, -50%) rotate(0.5turn);
    }
}
