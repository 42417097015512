/*------------------------------------*\
    $BRAND
\*------------------------------------*/

.brand-primary {
    color: $colour-primary;
}

.brand-secondary {
    color: $colour-secondary;
}
