.woo-container {
    width: 100%;
    max-width: 1536px;
    margin: 0 auto;
}
.dropdown-bg {
    background-color: #e5e5e5 !important;
}
.cat-container {
    .woo-category-filters {
        padding-top: 48px;
        margin: 0 30px 30px 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        @media screen and (max-width:768px) {
            flex-direction: column;
        }
        .filter-item {
            display: flex;
            flex-direction: row;
            margin: 0 26px 30px 0;

            @media screen and (max-width:768px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 0;
            }

            // Dropdown
            .cat-list {
                padding: 0;
                width: 250px;
                max-width: 250px;
                position: relative;
                display: inline-block;
                transition: all 0.3s ease-in-out;
                z-index: 10;

                @media screen and (max-width:768px) {
                    max-width: 100%;
                    width: 100%;
                }

                .cat-name {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    background-color: $colour-ui;
                    color: $base-text-colour;
                    padding: 16px;
                    font-size: 16px;
                    text-transform: uppercase;
                    text-align: left !important;
                    border: none;
                    border-top: 2px solid #dcdcdc;
                    border-bottom: 2px solid #dcdcdc;
                    transition: all 0.3s ease-in-out;
                }
                .dropdown-content {
                    display: none;
                    position: absolute;
                    background-color: #ededed;
                    width: 100%;
                    z-index: 50;
                    text-transform: uppercase;
                    margin-top: -14px;
                    border-radius: 0;
                    max-height: 400px;
                    overflow: auto;
                    &::before {
                        border: none;
                    }

                    .cat-list-item {
                        flex: 1 1 50%;
                        text-align: left !important;
                        color: $base-text-colour;
                        line-height: 1.4em;
                        text-decoration: none;
                        display: block;
                        a {
                            height: 100%;
                            display: block;
                            padding: 12px 16px;
                            color: $base-text-colour;
                            font-size: 13px;
                            line-height: 1.4em;
                        }
                        &:hover,
                        &:focus {
                            background-color: #ddd;
                        }
                    }
                }
                .arrow {
                    display: inline-block;
                    margin-left: 10px;
                    color: #848484;
                    transition: all 0.3s ease-in-out;
                    svg {
                        height: 12px;
                        width: 12px;
                    }
                }
                &.is-active,
                &:hover,
                &:focus {
                    .arrow {
                        color: #ddd;
                        svg {
                            transform: rotate(0.5turn);
                        }
                    }
                    .dropdown-content {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        z-index: 50;
                        @media screen and (max-width:768px) {
                            position: relative;
                            margin-top: 0;
                        }
                    }
                    .cat-name {
                        &.btn {
                            color: $colour-ui-light;
                            background-color: #262626;
                            text-align: left !important;
                        }
                    }
                }
            }

            &.widget-items {
                @media screen and (max-width:768px) {
                    flex-direction: column;
                    .widget {
                        margin-bottom: 30px;
                        margin-right: 0;
                        width: 100%;
                    }
                }
            }

            .widget {
                width: 250px;
                margin-bottom: 0;
                margin-right: 30px;

                &.widget_product_search {
                    @media screen and (max-width:768px) {
                        width: 100%;
                    }
                    margin-bottom: 0;
                    .woocommerce-product-search {
                        @media screen and (max-width:768px) {
                            display: flex;
                            flex-direction: row;
                        }
                    }
                    input {
                        padding: 15px;
                        text-transform: uppercase;
                        @media screen and (max-width:768px) {
                            width: auto;
                            flex: 1;
                        }
                    }
                    button {
                        text-transform: none;
                        color: #ffffff;
                        background: #447532;
                        border-radius: 0;
                        width: auto;
                        height: 100%;
                        clip: auto;
                        clip-path: none;
                        padding: 5px 10px;
                        @media screen and (max-width:768px) {
                            position: relative;
                            padding: 17px 10px;
                        }
                    }
                }

                &.widget_price_filter {
                    .widgettitle {
                        font-size: 13px;
                        text-transform: uppercase;
                        margin-bottom: 15px;
                    }
                    .price_slider_amount {
                        .button {
                            font-size: 12px;
                            padding: 0 12px;
                            border: none;
                        }
                    }
                }
            }
        }
    }
}