/*------------------------------------*\
    $LOGIN
\*------------------------------------*/

.login-btn {
    width: 240px;
}

.login-forgot {
    color: #999;
    text-decoration: underline;
}
