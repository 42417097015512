.cat-container {
    background-color: #EBEBEB;
    z-index: 10;

    .toggle-categories {
        width: 100%;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
        display: none;
        padding: 20px;
        .arrow {
            margin: 0 0 0 10px;
            color: #848484;
            transition: all 0.3s ease-in-out;
            svg {
                height: 12px;
                width: 12px;
            }
        }
        @media screen and (max-width:768px) {
            display: flex;
            justify-content: center;
        }
        &.is-active,
        &:hover,
        &:focus {
            background-color: #262626;
            color: $colour-ui-light;

            .arrow {
                color: #ddd;
                svg {
                    transform: rotate(0.5turn);
                }
            }
        }
    }
    .category-nav {
        display: flex;
        align-items: stretch;
        position: relative;
        z-index: 15;
        margin: 0;
        background-color: #EBEBEB;
        max-width: 100%;
        @media screen and (max-width:768px) {
            display: none;
            flex-direction: column;
            transition: all 300ms ease-in-out;

            &.is-active {
                display: flex;
            }
        }
        .nav-item {
            display: flex;
            flex-direction: row;
            flex: 1;
            line-height: 1.4em;
            border: none;
            min-height: 100%;
            margin: 0;
            padding: 10px 10px 0 0;

            @media screen and (max-width:768px) {
                flex-direction: column;
            }

            .nav-link {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 900;
                padding: 12px;
                padding-right: 0;
            }

            .arrow {
                display: flex;
                margin: 12px 0 12px 10px;
                color: #848484;
                transition: all 0.3s ease-in-out;
                svg {
                    height: 12px;
                    width: 12px;
                }
                @media screen and (max-width:768px) {
                    // display: none;
                    display: block;
                    position: absolute;
                    top: 2px;
                    right: 10px;
                    padding: 10px;
                }
            }

            &.is-active,
            &:hover,
            &:focus {
                background-color: #262626;
                @media screen and (max-width:768px) {
                    background-color: transparent;
                }

                .nav-link {
                    color: $colour-ui-light;

                    @media screen and (max-width:768px) {
                        color: $colour-ui-dark;
                    }
                }
                .arrow {
                    color: #ddd;
                    svg {
                        transform: rotate(0.5turn);
                    }
                }
            }

            .nav-link {
                display: inline-block;
                flex: 1;
                height: 100%;

                @media screen and (max-width:768px) {
                    margin-right: 24px;
                }

                div {
                    height: auto;
                    padding-bottom: 5px;
                }
                &.is-current {
                    div {
                        border-bottom: 2px solid $colour-tertiary;
                    }
                }
            }
            a {
                color: $base-text-colour;
                text-transform: uppercase;
                font-weight: 600;
                display: flex;
                height: 100%;
            }
            .subnav-list {
                display: flex;
                flex: 1;
                width: 100%;
                margin-top: -14px;
                border-radius: 0;

                @media screen and (max-width:768px) {
                    flex-direction: column;
                    display: none;

                    .is-active & {
                        display: flex;
                    }
                }

                &::before {
                    border: none;
                }
                .subnav-link {
                    color: $base-text-colour;
                    font-weight: $font-weight-normal;
                    font-size: 13px;
                    padding: 12px;
                    white-space: normal;

                    &:hover,
                    &:focus {
                        background-color: #ddd;
                    }

                    &.is-current {
                        border-bottom: 2px solid $colour-tertiary;
                    }
                }
            }
        }
    }
    &.home-cat-container {
        position: relative;
        z-index: 10;
        background-color: rgba(0,0,0,0.6);
        margin-bottom: 20px;
        .category-nav, .nav-link, .nav-item, .subnav-list, .toggle-categories {
            background-color: transparent;
            color: #ffffff !important;
        }
        .subnav {
            .nav-link {
                @media screen and (max-width:768px) {
                    margin-right: 24px;
                }
            }

            &.is-active {
                @media screen and (max-width:768px) {
                    background-color: transparent;
                }
            }
        }
        .subnav-list {
            transition: all 0.5s ease-in-out;

            .subnav-item {
                background-color: rgba(0,0,0,0.6);
                @media screen and (max-width:768px) {
                    background-color: transparent;
                }
                &:not(:first-child) {
                    border-top: 2px solid #848484;
                    @media screen and (max-width:768px) {
                        border-top: none;
                    }
                }
                .subnav-link {
                    background-color: #262626;
                    color: #ffffff;
                    @media screen and (max-width:768px) {
                        background-color: transparent;
                    }
                    &:hover,
                    &:active {
                        background-color: #262626;
                    }
                }
            }
        }
    }
}
