/*------------------------------------*\
    $GALLERY
\*------------------------------------*/

.gallery {
    position: relative;

    .slick-arrow {
        background-color: rgba($colour-ui-dark, 0.4);
        color: rgba($colour-ui-light, 0.6);

        &:hover {
            color: $colour-ui-light;
        }
    }

    @include media-query(palm) {
        margin: 0 -4px halve($base-spacing-unit);

        .slick-list {
            overflow: visible;
        }
    }

    @include media-query(lap) {
        overflow: hidden;
        margin-bottom: 6px;
    }
}

.gallery-slide {
    position: relative;
    background-color: $colour-ui-dark;
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
        button {
            transition: 0.3s;
            transition-property: opacity;
            opacity: 0.8;
            &:hover {
                opacity: 1;
            }
        }
    }
    &::before {
        content: "";
        display: block;
        padding-top: percentage(2/3);
    }
    @include media-query(palm) {
        margin-left: 4px;
        margin-right: 4px;
        background-color: $colour-ui-dark;
    }

    .gallery-zoom-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 10;
        border-radius: 100%;
        background-color: #ffff;
        padding: 6px;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    .zoomImg {
        cursor: pointer;
    }
}

.gallery-figure,
.gallery-figure-alt {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover";
    opacity: 0;
    transition: opacity $base-anim-speed;
    cursor: pointer;
    .modal-content & {
        cursor: default;
    }

    &.lazyloaded {
        opacity: 1;
    }

    @include media-query(palm) {
        &.lazyloaded {
            opacity: 0.5;

            .slick-current & {
                opacity: 1;
            }
        }
    }
}

/**
 * Thumbnails
 */

.gallery-thumbs-wrapper {
    padding-right: 55px;
    overflow: hidden;

    @include media-query(palm) {
        margin-left: -$base-spacing-unit;
        margin-right: -$base-spacing-unit;
    }
}

.gallery-thumbs {
    position: relative;
    margin-right: -10px;

    &:not(.slick-initialized) {
        display: flex;

        .gallery-thumb:nth-child(-n + 3) {
            display: block !important;
            width: calc(2 / 3 * 100% - 10px);
        }
    }

    .slick-list {
        overflow: visible;
    }
}

.gallery-thumb {
    position: relative;
    background-color: $colour-ui-dark;
    margin-right: 10px;
    overflow: hidden;

    &::before {
        content: "";
        display: block;
        padding-top: percentage(2/3);
    }

    &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $colour-tertiary;
        transition: transform $base-anim-speed ease-in;
    }

    &.slick-current {
        &::after {
            transition-timing-function: ease-out;
            transform: translateY(-100%);
        }
    }

    @include media-query(lap) {
        &::after {
            height: 3px;
        }
    }
}

.gallery-thumb-figure,
.gallery-thumb-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.gallery-thumb-figure {
    object-fit: cover;
    font-family: "object-fit: cover";
    transition: opacity $base-anim-speed;
    opacity: 0;

    &.lazyloaded {
        opacity: 0.5;

        .slick-current & {
            opacity: 1;
        }
    }

    @include media-query(lap) {
        &.lazyloaded {
            opacity: 1;

            .slick-current & {
                opacity: 0.3;
            }
        }
    }
}
