/*------------------------------------*\
    $QUOTE
\*------------------------------------*/

.quote-band {
    position: relative;
}

.quote-slider {
    margin-left: -6px;
    margin-right: -6px;

    &:not(.slick-initialized) {
        @include media-query(lap) {
            .quote:nth-child(-n + 2) {
                display: flex !important;
                width: calc(50% - #{$base-spacing-unit});
            }
        }
    }

    .slick-arrow {
        background-color: #c1c4c3;
        color: #e9eaea;
    }

    &:not(.slick-initialized),
    // .slick-list,
    .slick-track {
        display: flex;
    }

    @include media-query(palm) {
        .slick-list {
            width: 100%;
            overflow: visible;
        }
    }

    @include media-query(lap) {
        margin-left: 0 - halve($base-spacing-unit);
        margin-right: 0 - halve($base-spacing-unit);
    }
}

.quote {
    @include font-size(17px, 28px);
    display: flex;
    // flex: 1 0 auto;
    position: relative;
    text-align: center;
    margin-left: 6px;
    margin-right: 6px;
    padding: 40px $base-spacing-unit;
    background-color: $colour-ui-light;

    @include media-query(lap) {
        margin-left: halve($base-spacing-unit);
        margin-right: halve($base-spacing-unit);
    }
}

.quote-content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 400px;
    width: 100%;

    p {
        margin-bottom: 40px;
    }
}

.quote-source {
    @include font-size(15px, 28px);
    text-transform: uppercase;
    margin-top: auto;
    font-weight: $font-weight-bold;
}

.quote-icon {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 45px;
    height: 45px;
    color: #ededed;
    transform: translateZ(0);
}
