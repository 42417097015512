/*------------------------------------*\
    $DATA
\*------------------------------------*/

.data {
    display: table;
    width: calc(100% + #{double($base-spacing-unit)});
    margin-left: -$base-spacing-unit;
    margin-right: -$base-spacing-unit;
}

.data-row {
    display: table-row;

    &:not(:first-child) dt,
    &:not(:first-child) dd {
        border-top: 2px solid #d9dedb;
    }
}

.data {
    dt, dd {
        @include font-size(18px);
        display: table-cell;
        padding-top: 12px;
        padding-right: $base-spacing-unit;
        padding-bottom: 12px;
    }

    dt {
        padding-left: $base-spacing-unit;
    }

    dd {
        text-align: right;
        font-weight: $font-weight-semibold;
    }
}


.data-contact {
    margin-left: 0;
    margin-right: 0;
    width: auto;

    dt, dd {
        @include font-size($base-font-size);
        border: none;
        padding: 0;
    }

    dt {
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }

    dd {
        text-align: left;
        font-weight: normal;
    }
}
