/*------------------------------------*\
    $BLEED
\*------------------------------------*/

.bleed {
    margin-left: -$base-spacing-unit;
    margin-right: -$base-spacing-unit;
}

@include media-query(palm) {
    .bleed-palm {
        margin-left: -$base-spacing-unit;
        margin-right: -$base-spacing-unit;
    }
}

.bleed-remove {
    margin-left: $base-spacing-unit;
    margin-right: $base-spacing-unit;
}
