/*------------------------------------*\
    $LEDE
\*------------------------------------*/

.lede {
    @include font-size(18px, (39px / 24px));

    @include media-query(lap) {
        @include font-size(24px, false);
    }
}

.lede-bordered {
    border-top: 3px solid $colour-primary;
    padding-top: 20px;
}
