/*------------------------------------*\
    $VIDEO
\*------------------------------------*/

.video {
    position: relative;
    background-color: #131816;
    margin-bottom: $base-spacing-unit;

    &::before {
        content: "";
        padding-top: 56.25%;
        display: block;
    }

    embed,
    iframe,
    object {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @include media-query(palm) {
        margin-left: -$base-spacing-unit;
        margin-right: -$base-spacing-unit;
    }
}
