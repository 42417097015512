/*------------------------------------*\
    $BRANCHES
\*------------------------------------*/

.branches-heading {
    margin-top: double($base-spacing-unit);

    @include media-query(desk) {
        margin-bottom: 0;
    }
}

.branches {
    @include media-query(desk) {
        margin-bottom: $base-spacing-unit;
    }
}

.branches-item {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        display: none;
        top: 0;
        left: -$base-spacing-unit;
        right: -$base-spacing-unit;
        background-color: #fafafa;
        border-top: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
        height: 100%;

        @include media-query(desk) {
            width: calc(200% + #{$base-spacing-unit + 68px * 2});
            left: -68px;
        }
    }

    @include media-query(portable) {
        &:nth-of-type(even) {
            padding-top: $base-spacing-unit;
            margin-bottom: $base-spacing-unit;

            &::before {
                display: block;
            }
        }
    }

    @include media-query(desk) {
        padding-top: 45px;
        padding-bottom: 15px;

        &:nth-child(4n-1) {
            &::before {
                display: block;
            }
        }
    }
}

.branches-inner {
    position: relative;
}


.branches-contact {
    display: flex;
    align-items: center;
}

.branches-contact-figure {
    border-radius: 99px;
    background-color: $colour-primary;
    color: $colour-ui-light;
    width: 54px;
    height: 54px;
    margin-bottom: $base-spacing-unit;
    margin-right: 35px;
    position: relative;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 21px;
        height: 21px;
    }
}

    .branches-contact-figure-secondary {
        background-color: $colour-secondary;
    }

.branches-contact-body {
    flex: 1 0 auto;
    font-weight: $font-weight-bold;
}
