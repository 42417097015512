/*------------------------------------*\
    $WIDGET
\*------------------------------------*/

.widget {
    @include media-query(desk) {
        &:not(:last-child) {
            margin-bottom: double($base-spacing-unit);
        }
    }

    .typography.typography-lrg {
        line-height:1.3;
    }
}

.widget-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.widget-body {
    @include font-size(19px, 23px);
    border-left: 3px solid $colour-ui;
    padding-left: 35px;
    max-width: 250px;
}

.widget-link {
    @include font-size(15px, 18px);
    display: inline-block;
    position: relative;
    padding-left: 35px;
    color: $colour-ui-dark;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;

    .icon {
        color: $colour-primary;
        position: absolute;
        top: 0.6em;
        left: 0;
        transform: translateY(-50%);
        transition: transform $base-anim-speed ease-in-out;
    }

    &:hover {
        color: $colour-primary;

        .icon {
            transform: translate(5px, -50%);
        }
    }
}

.widget-vacancy {
    margin-bottom: 0;
}

.widget-vacancy-meta {
    @include font-size(12px);
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

.widget-team-thumbs {
    display: flex;
    margin-bottom: $base-spacing-unit;
    margin-left: -6px;
}

.widget-team-thumb {
    width: 38px;
    margin-bottom: 0;
    margin-left: 6px;

    &,
    img {
        border-radius: 100%;
    }

    &:nth-child(2) {
        opacity: 0.65;
    }

    &:nth-child(3) {
        opacity: 0.3;
    }
}


/**
 * Search
 */

.woocommerce-product-search,
.widget-search {
    position: relative;
    margin-bottom: $base-spacing-unit;

    .icon {
        position: absolute;
        top: 12px;
        left: 0;
        transform: translateY(-50%);
    }
}

.woocommerce-product-search {
    &::before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        top: 12px;
        transform: translateY(-50%);
        left: 0;
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:a='http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/' x='0px' y='0px' width='18px' height='18px' viewBox='0 0 38.5 38.5' style='enable-background:new 0 0 38.5 38.5;' xml:space='preserve'%3E%3Cdefs%3E%3C/defs%3E%3Cpath fill='#447532' d='M16.5,7c5.2,0,9.5,4.3,9.5,9.5S21.7,26,16.5,26S7,21.7,7,16.5S11.3,7,16.5,7 M16.5,0C7.4,0,0,7.4,0,16.5S7.4,33,16.5,33 S33,25.6,33,16.5S25.6,0,16.5,0L16.5,0z'/%3E%3Crect fill='#447532' x='27.5' y='23.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -12.8428 31.0054)' width='7' height='14.2'/%3E%3C/svg%3E%0A");
    }
}

.woocommerce-product-search input,
.widget-search-terms {
    @include font-size(17px);
    color: $colour-ui-dark;
    border: none;
    border-bottom: 2px solid $colour-ui;
    padding: 0 0 12px 36px;
    width: 100%;
    transition: border-color $base-anim-speed;

    &::placeholder {
        color: currentColor;
    }

    &:focus {
        border-bottom-color: $colour-secondary;
        outline: none;
    }
}

.woocommerce-product-search button {
    @include visuallyhidden;
}


/**
 * Widget Sliders
 */

.widget-slider-wrap {
    overflow: hidden;
    margin: 0 (0 - halve($base-spacing-unit)) $base-spacing-unit;
}

.widget-slider-arrows {
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
    margin-left: 15px;

    .slick-arrow {
        position: relative;
        height: 38px;
        transform: none !important;
        width: calc(50% - 7px);
        background-color: #e8ebea;
        margin-top: 33px;

        .icon {
            width: 9px;
            height: 15px;
            right: auto;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .slick-prev {
        .icon {
            transform: translate(-50%, -50%) rotate(0.5turn);
        }
    }
}

.widget-slider {
    position: relative;
    margin-bottom: 0;
}

.widget-slide {
    background-color: #e8ebea;
    padding: $base-spacing-unit $base-spacing-unit 1px;
    margin-left: halve($base-spacing-unit);
    margin-right: halve($base-spacing-unit);
    background-color: #e8ebea;
}

.widget-slide-thumb {
    margin: (0 - $base-spacing-unit) (0 - $base-spacing-unit) $base-spacing-unit;
}

.widget-slide-meta {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
}


/**
 * Custom links
 */

.widget-custom-link {
    @include font-size(16px);
    background-color: #ededed;
    display: block;
    text-transform: uppercase;
    padding: halve($base-spacing-unit) 38px;
    color: $colour-ui-dark;
    font-weight: $font-weight-semibold;

    span {
        position: relative;
        display: inline-block;
        padding-right: 20px;
    }

    .icon {
        position: absolute;
        top: 50%;
        right: 0;
        width: 7px;
        height: 11px;
        transform: translateY(-50%);
        color: $colour-primary;
        transition: transform $base-anim-speed;
    }

    &:hover {
        .icon {
            transform: translate(5px, -50%);
        }
    }
}


/**
 * Product widget
 */

.widget {
    .product {
        width: 100% !important;
        margin-left: 0 !important;
        margin-bottom: $base-spacing-unit;
    }
}


/**
 * Text/Image Widgets
 */

.widget-text {
    &::after {
        content: "";
        display: block;
        margin-top: (0 - halve($base-spacing-unit));
        margin-bottom: $base-spacing-unit;
        width: 67.5px;
        border-top: 3px solid $colour-primary;
    }
}


/**
 * Masonry Widgets
 */

.masonry-widgets,
.masonry-widgets-left,
.masonry-widgets-right,
.masonry-widgets .grid-item {
    display:flex;
    flex-direction:column;
    flex-wrap:nowrap;

    & > .grid {
        flex:1;
    }

    @include media-query(portable) {
        display:none;
    }
}
.masonry-widgets-right {
    margin-top:-12px; margin-bottom:-12px;
}

.masonry-widgets .item {
    flex:1;
    position:relative;
    z-index:1;

    &.small {
        margin:12px 0;
    }

    img,
    .text {
        position:absolute;
        top:0; left:0; right:0; bottom:0;
        width:100%; height:100%;
    }

    img {
        z-index:2;
        object-fit:cover;
        font-family:'object-fit:cover';
    }

    .text {
        display:flex;
        flex-direction:column;
        flex-wrap:nowrap;
        z-index:3;
        padding:24px;
        color:#FFF;
        background:rgba($base-text-colour, .60);

        .link {
            margin:auto 0 0;

            .btn {
                margin-left:-24px;
            }
        }
    }
}

.LoginBox a { color: #005c34 }
.LoginBox {background-color:#f0f1f5;border:1px solid #D1D1D0;font-size:11px;font-family:Arial;}
.LoginBox .bottom { clear:both;padding:20px 20px 10px 20px; text-align: center;}
.LoginBox .LoginBox_logo {background-color:#fff;padding:25px 20px; border-bottom:#feed00 7px solid; text-align: center; font-size: 15px; font-weight: bold;}
.LoginBox .LoginForm { border-top: #005c34 7px solid; }
.LoginBox .formfield {padding:10px 10px 0 10px;}
.LoginBox .input{width: 100%; padding: 2px 0px;}
.LoginBox .forgetPW{padding:5px 10px 0 10px;}
.LoginBox .MoreInfoLink{ float: left; margin-top: 7px;}
.LoginBox .button { background:#fc0; font-weight: bold; color: #000; border: 1px solid #b61e2a; border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25); display: inline-block; margin-bottom: 0; text-align: center; vertical-align: middle; cursor: pointer; box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); margin: 0; outline: 0; font-size: 12px; -webkit-border-radius: 2px; border-radius: 2px; float:right; padding: 5px; }
