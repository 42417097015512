/*------------------------------------*\
    $NOTICE
\*------------------------------------*/

.notice {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    background-color: rgba($colour-primary, .95);
    color: $colour-ui-light;
    padding: quarter($base-spacing-unit) $base-spacing-unit;
    transform: translateY(100%);
    transition: transform $base-anim-speed ease-in;

    @include media-query(nav-portable) {
        padding-bottom: 47px + quarter($base-spacing-unit);
    }

    &.is-active {
        transform: none;
        transition-timing-function: ease-out;
    }

    button, a {
        &:focus {
            outline-color: $colour-ui-light;
        }
    }
}

.notice-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    > p {
        margin: quarter($base-spacing-unit) halve($base-spacing-unit);
    }
}

.notice-text {
    margin-bottom: 0;
    text-align: center;

    a  {
        color: $colour-ui-light;
        font-weight: $font-weight-bold;

        &:hover {
            text-decoration: underline;
        }
    }
}
