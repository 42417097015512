/*------------------------------------*\
    $SUBNAV
\*------------------------------------*/

// Define mixin separately as same ruleset is used in
// multiple places

@mixin subnav-active {
    & > .subnav-list {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
        transform: translateX(-50%);

       &.subnav-list-2nd-lvl {
           transform: translateY(0);
       }
    }

    & > .nav-link,
    & > .subnav-toggle {
        color: $colour-primary;

        .nav-secondary & {
            color: $colour-tertiary;
        }

        .header-controls & {
            color: $colour-ui-light;
        }

        .icon {
            transform: translateY(-50%);
        }
    }

    & > .nav-link-ui {
        .icon {
            transform: none;

            @media screen and (min-width: 1150px) {
                transform: translateY(-50%);
            }
        }
    }
}

.subnav {
    position: relative;
    padding-right: 30px;
    z-index: 1;

    .header-controls & {
        padding-right: 0;
    }

    @include media-query(nav) {
        padding-right: 18px;

        &:hover,
        // &:focus-within,
        &.is-active {
            @include subnav-active;
        }

        /**
         * Needs to be separate, otherwise breaks the entire selector,
         * i.e. :hover does nothing
         */

        &:focus-within {
            @include subnav-active;
        }

        &.is-active {
            .subnav-toggle .icon {
                transform: translateY(-50%) rotate(0.75turn);
            }
        }

        .header-controls &,
        .nav-secondary & {
            z-index: 2;
        }

        .nav-secondary & {
            padding-right: 0;
        }
    }
}

.subnav-toggle {
    // display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    transition: color $base-anim-speed;

    .icon {
        position: absolute;
        top: 50%;
        right: 0;
        width: 12px;
        height: 12px;
        transform: translateY(-50%);
        transition: transform $base-anim-speed ease-in-out;

        @include media-query(nav) {
            margin-top: 1px;
            width: 10px;
            height: 10px;
            color:$colour-ui-dark;
            transform: translateY(-50%) rotate(0.25turn);
        }
    }

    &.is-active {
        .icon {
            transform: translateY(-50%) rotate(0.25turn);
        }
    }

    @include media-query(nav) {
        width: 18px;
        height: 18px;
        top: auto;
        bottom: 0.6em;
        transform: translateY(50%);

        .nav-link.is-current + & {
            color: $colour-primary;
        }

        .nav-secondary & {
            display: none;
        }

        .subnav-item & {
            display: block !important;
            top:50%; right:14px; bottom:auto;
            transform:translateY(-50%);
        }
    }
}

.subnav-list {
    display: none;
    margin-bottom: 0;

    @include media-query(nav-portable) {
        &::before {
            content: "";
            display: block;
            height: halve($base-spacing-unit);
        }

        &.subnav-list-2nd-lvl::after {
            content: "";
            display: block;
            height: halve($base-spacing-unit);
        }
    }

    @include media-query(nav) {
        display: block !important;
        position: absolute;
        top: calc(100% + 14px);
        left: 50%;
        min-width: 100%;
        visibility: hidden;
        // padding: 12px 0;
        opacity: 0;
        border-radius: 4px;
        background-color: #ededed;
        transform: translate(-50%, -5px);
        transition: opacity $base-anim-speed, visibility 0s $base-anim-speed, transform $base-anim-speed;
        box-shadow: 0 1px rgba(#000, .08);

        &::before {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            border: 14px solid transparent;
            border-top-width: 0;
            border-bottom-color: #ededed;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 0;
            right: 0;
            height: 14px;
        }

        &.subnav-list-2nd-lvl {
            top:0; left:calc(100% + 12px);
            transform: translate(5px, 0%);

            &::before {
                top:17px; left:-15px; bottom:auto;
                border:10px solid transparent;
                border:10px solid transparent;
                border-top-width:0;
                border-bottom-color:#ededed;
                transform:rotate(-90deg);
            }
            &::after {
                top:0; left:-12px; right:auto; bottom:0;
                width:12px; height:auto;
            }
        }

        .nav-secondary > :first-child & {
            left: -15px;
            transform: translateY(-5px); //translateY(-5px);

            &::before {
                left: 15px;
                transform: none;
            }
        }

        .nav-secondary > :first-child:hover & {
            transform: none;
        }

        .nav-secondary > :first-child:focus-within & {
            transform: none;
        }
    }
}

.subnav-item {
    @include media-query(nav) {
        border-top: 2px solid #e0e0e0;

        &:first-child {
            border-top:none;
        }
        /*
        &:last-child {
            border-bottom: 2px solid #e0e0e0;
        }
        */
    }
}

.subnav-link {
    color: rgba($colour-ui-light, .75);
    font-weight: $font-weight-semibold;

    &:hover {
        color: $colour-tertiary;
    }

    &.is-current {
        text-decoration: underline;
    }

    @include media-query(nav) {
        @include font-size(13px, 18px);
        display: block;
        padding: 12px 35px;
        white-space: nowrap;
        text-transform: uppercase;
        color: $colour-ui-dark;

        &:hover,
        &.is-current {
            text-decoration: none;
            color: $colour-primary;
        }
    }
}

.subnav-link-icon {
    @include media-query(nav) {
        position: relative;
        padding-right: double($base-spacing-unit);

        .icon {
            position: absolute;
            top: 50%;
            right: 37px;
            transform: translate(50%, -50%);
        }
    }
}
button.subnav-link-icon {
    width:100%;
}

.subnav-account {
    display: flex;
    padding: 0 12px 12px;
    min-width: 255px;
}

.subnav-account-link {
    @include font-size(13px, 15px);
    display: block;
    width: 50%;
    position: relative;
    padding-top: double($base-spacing-unit);
    padding-bottom: 8px;
    padding: double($base-spacing-unit) 15px 8px;
    text-transform: uppercase;
    color: $colour-ui-dark;
    font-weight: $font-weight-semibold;
    text-align: center;

    &:hover {
        color: $colour-primary;
    }

    .icon {
        position: absolute;
        width: 32px;
        height: 29px;
        top: 30px;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $colour-primary;
    }
}

.subnav-account-welcome {
    @include font-size(13px, 15px);
    display: flex;
    flex-direction:row;
    flex-wrap:nowrap;
    position: relative;
    padding: 12px 23px;
    color: $colour-ui-dark;
    font-weight: $font-weight-semibold;

    .icon {
        margin-right:18px;
        width:32px; height:29px;
    }
}
