/*------------------------------------*\
    $MACHINERY-BRAND
\*------------------------------------*/

.machinery-brand {
    @include grid(0px);
    flex-direction: row-reverse;
    margin-bottom: $base-spacing-unit;
}

.machinery-brand-body,
.machinery-brand-figure {
    @include grid-item(0px);

    @include media-query(lap) {
        @include grid-width(1/2, 0px);
    }
}

.machinery-brand-body {
    padding: $base-spacing-unit 50px;
    color: #808080;
    background-color: #ebedec;
    border-radius: 0 0 3px 3px;

    .btn-plain {
        color: $colour-secondary;

        &:hover {
            color: $colour-ui-dark;
        }
    }

    &::after {
        content: "";
        display: block;
        margin-bottom: -$base-spacing-unit
    }

    @include media-query(lap) {
        border-radius: 3px 0 0 3px;
    }
}

.machinery-brand-body-full {
    @include media-query(lap) {
        @include grid-width(1/1, 0px);
    }
}

.machinery-brand-title {
    @include font-size(21px);
    color: $colour-ui-dark;
    font-weight: $font-weight-semibold;
}

.machinery-brand-figure {
    margin-bottom: 0;
    background-color: $colour-ui-dark;

    .lazyloaded {
        opacity: 0.8;
    }

    &,
    img {
        border-radius: 3px 3px 0 0;

        @include media-query(lap) {
            border-radius: 0 3px 3px 0;
        }
    }

}

.machinery-brand-logo {
    background-color: $colour-ui-light;
    border: 1px solid #e6e6e6;

    .lazyloaded {
        opacity: 1;
    }

    img {
        max-width: 80%;
        left: 50%;
        transform: translateX(-50%);
        object-fit: contain;
        font-family: 'object-fit: contain;';
    }
}
