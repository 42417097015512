/*------------------------------------*\
    $FILTERS
\*------------------------------------*/

.filters {
    .field-select {
        .field-control {
            padding-right: $base-spacing-unit;
        }

        .icon {
            right: 15px;
            width: 14px;
            height: 8px;
            color: $colour-ui-dark;
        }
    }
}

.filters-dropdowns {
    @include media-query(portable) {
        padding-top: 0;
    }

    @include media-query(desk) {
        border-top: 1px solid $colour-ui;

        .field {
            margin-bottom: 18px;
        }
    }
}

.filters-dropdowns-summary {
    @include media-query(desk) {
        display: none;
    }
}

.filters-dropdowns-toggle {
    @include font-size(16px);
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
    text-decoration: underline;
    color: $colour-ui-dark;
    transition: color $base-anim-speed;

    &:hover {
        color: $colour-primary;
    }
}

.filters-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.filters-tags-item {
    margin-left: 5px;
    margin-right: 5px;
}

.filters-tags-link {
    @include font-size(13px, 18px);
    display: block;
    padding: 5px halve($base-spacing-unit);
    border-radius: 4px;
    background-color: #707a76;
    color: $colour-ui-light;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    transition: background-color $base-anim-speed;

    /*
    &:hover {
        background-color: $colour-quaternary;
        color: $colour-ui-light;
    }
    */
}

.filters-dropdowns-body {
    @include media-query(portable) {
        display: none;

        &::after {
            content: "";
            display: block;
            height: $base-spacing-unit - 6px;
        }
    }

    @include media-query(desk) {
        display: block !important;

        button {
            margin-top:33px;
        }
    }
}

.filters-options {
    padding: 20px 20px 0;
    border-top: 9px solid $colour-ui !important;
    border-bottom: 1px solid $colour-ui;

    .field {
        margin-bottom: 0;
    }

    @include media-query(desk) {
        padding-top: 0;
    }
}

.filters + .filters .filters-options {
    padding:12px 24px;
    border-top:none !important;
}

.filters-sort {
    @include media-query(portable) {
        @include grid-width(1/1);
        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: -$base-spacing-unit;
            right: -$base-spacing-unit;
            border-bottom: 1px solid $colour-ui;
        }

        .field {
            display: block;
            margin-bottom: 20px;
        }
    }

    @media (max-width: 959px) {
        .field-label {
            @include visuallyhidden;
        }
    }
}

.filters-sort-list {
    @include grid(20px);

    @include media-query(desk) {
        flex-wrap: nowrap;
    }
}

.filters-sort-item {
    @include grid-item(20px, 1/3);

    &:nth-child(2) {
        @include grid-width(2/3, 20px);
    }

    @include media-query(desk) {
        @include grid-width(auto, 20px);
    }
}

.filters-grid {
    @include media-query(desk) {
        flex-wrap: nowrap;
        justify-content: space-between;
    }
}

.filters-layout {

    @include media-query(portable) {
        display:none;
    }
    @include media-query(desk) {
        display: flex;
    }
}

.filters-layout-check {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.filters-layout-btn {
    @include font-size(14px, 18px);
    position: relative;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    color: $colour-ui-dark;
    padding: 26px 15px;
    transition: color $base-anim-speed, box-shadow $base-anim-speed ease-in-out;
    box-shadow: inset 0 0 $colour-primary;

    &:not(.is-active):hover,
    input:checked ~ & {
        color: $colour-primary;
        box-shadow: inset 0 -3px $colour-primary;
    }

    input:focus ~ & {
        @include focus;
    }

    .icon {
        margin-right: 8px;
    }

    @include media-query(portable) {
        &:first-child {
            margin-left: -20px;
            padding-left: 20px;
        }
    }

    &.is-active {
        cursor:default;
        color: $colour-primary;
    }
}

.filters-featured {
    @include media-query(portable) {
        width: 100% !important;
        padding: 12px 0;
    }
    @include media-query(desk) {
        width: 160px !important;
    }
}


.filters-showing {
    font-weight: $font-weight-semibold;
    line-height:1.2;

    @include media-query(desk) {
        width: 130px !important;
    }
}

.filters-currency .field-control {
    min-width: 90px;
}

.filters-print {
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;

    @media (max-width: 959px) {
        display: none;
    }
}

.filters-posts {
    .filters-showing {
        width: auto !important;
        margin-right: auto;
    }

    .filters-grid {
        justify-content: flex-start;
    }

    .filters-options {
        padding-top: 0;
        border-top: 1px solid #e6e6e6 !important;
    }
}

.filters-staff {
    @include media-query(portable) {
        padding-bottom: 24px;
        .check {
            margin-bottom: 12px;
        }
    }
}

.filters-staff-group {
    @include media-query(desk) {

        .field:not(:last-child) {
            padding-bottom: 4px;
        }
    }
}
