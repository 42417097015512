/*------------------------------------*\
    $TOGGLE
\*------------------------------------*/

.js-toggle {
    cursor:pointer;

    svg {
        transform:rotate(90deg);
        transition:transform $base-anim-speed;
    }

    &.open svg {
        transform:rotate(-90deg);
    }
}
