/*------------------------------------*\
    $LINKS
\*------------------------------------*/

a {
    color: $colour-primary;
    text-decoration: none;
    transition: color $base-anim-speed;

    &:hover {
        color: $colour-ui-dark;
    }
}
