/*------------------------------------*\
    $VACANCY
\*------------------------------------*/

.vacancy-list {
    @include media-query(palm) {
        margin-left: -20px;
        margin-right: -20px;
    }
}

.vacancy {
    padding: $base-spacing-unit $base-spacing-unit 1px;
    border: 2px solid $colour-ui;
    color: #808080;

    &:not(:last-child) {
        margin-bottom: halve($base-spacing-unit);
    }

    @include media-query(desk) {
        position: relative;
        padding-left: double($base-spacing-unit);
        padding-right: 240px;
    }
}

.vacancy-title {
    color: $colour-ui-dark;
}

.vacancy-meta {
    margin-bottom: halve($base-spacing-unit);
    font-weight: $font-weight-semibold;

    > .grid-item {
        margin-bottom: halve($base-spacing-unit);
    }
}

.vacancy-location {
    @include font-size(13px);
    padding: 2px 22px;
    display: inline-block;
    font-weight: $font-weight-bold;
    color: #6f8378;
    background-color: #dee5e1;
    border-radius: 3px;
    transition: background-color $base-anim-speed, color $base-anim-speed;

    &:hover {
        background-color: $colour-quaternary;
        color: $colour-ui-light;
    }
}

.vacancy-more-info {
    @include media-query(desk) {
        position: absolute;
        margin-bottom: 0;
        right: double($base-spacing-unit);
        top: 50%;
        transform: translateY(-50%);

        .btn {
            font-weight: $font-weight-semibold;
            padding: 0 42px 0 0;
            background-color: transparent;
            color: #b2b2b2;
            text-transform: none;

            &:hover {
                color: $colour-primary;
            }

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%) rotate(-45deg);
                border-right: 3px solid;
                border-bottom: 3px solid;
                width: 36px;
                height: 36px;
            }
        }

        .icon {
            display: none;
        }
    }
}
