
/*------------------------------------*\
    $WOOCOMMERCE - GENERIC
\*------------------------------------*/

.woocommerce-breadcrumb-wrapper {
    display:flex;

    .woocommerce-breadcrumb {
        display:flex;
        align-items:center;
        margin-bottom:0; padding:19px 24px;
        color:#262626;
        font-weight:600;
        border-bottom:2px solid #e6e6e6;

        a {
            position:relative;
            color:#999;
            font-weight:400;

            &:last-child:after {

            }
        }

        .chevron {
            padding:0 12px;
            width:5px; height:8px;
            fill:#999;
            box-sizing:content-box;
        }
    }

    @include media-query(portable) {
        display: none;
    }
}

.woocommerce {
    .woocommerce-result-count {

        @include media-query(desk) {
            height:55px;
            line-height:54px;
        }

        & + form {
            @include media-query(nav-portable) {
                width:100%;
            }
        }
    }

    table.shop_attributes {
        border-top:0;

        th, td {
            border-bottom:1px solid $colour-ui;
            background:none !important;
        }
        th {
            padding-left:0;
            color:$colour-ui-dark;
            text-align:left;
            text-transform:uppercase;
        }
        td {
            font-style:normal;
        }
    }

    table.shop_table {
        border:none !important;
        border-radius:0;
        border-collapse:collapse !important;

        @include media-query(desk) {
            border:1px solid $colour-ui;
        }
        @include media-query(nav-portable) {

            &.cart {
                border-top:none;
            }
        }

        th, td {
            margin:0 !important;
            background:none !important;
            border:1px solid $colour-ui !important;
            border-collapse:collapse !important;
        }
        th {
            color:$colour-ui-dark;
            text-align:left;
            text-transform:uppercase;
        }
        td {
            font-style:normal;

            @include media-query(nav-portable) {
                border-top:none !important;
            }

            &.product-remove {

                @include media-query(desk) {
                    width:49px;
                }
                @include media-query(nav-portable) {
                    position:relative;
                    z-index:2;
                    padding:0;
                    border:none !important;

                    a.remove {
                        position:absolute;
                        z-index:2;
                    }
                }
            }
            &.product-thumbnail {

                @include media-query(desk) {
                    width:89px;
                }
                @include media-query(nav-portable) {
                    display:block;
                    margin-top:12px !important;
                    border-top:1px solid $colour-ui !important;

                    &:before {
                        display:none;
                    }
                }

                a {
                    display:block;
                    text-align:left;
                }
            }
            &.product-price,
            &.product-subtotal {
                text-align:right;

                @include media-query(desk) {
                    width:109px;
                }
            }
            &.product-quantity {

                @include media-query(desk) {
                    width:89px;
                }
                @include media-query(nav-portable) {
                    display:flex;
                    flex-direction:row;
                    flex-wrap:nowrap;
                    justify-content: space-between;
                    align-items: center;
                }

                input {
                    @extend .field-control;
                    line-height:normal;

                    @include media-query(desk) {
                        display:block;
                        padding-right:8px;
                        width:100%;
                    }
                    @include media-query(nav-portable) {
                        width:80px;
                    }
                }
            }
            &.actions {

                @include media-query(nav-portable) {
                    margin-top:12px !important; padding:0;
                    border:none !important;
                }

                .coupon input[type=text] {
                    @extend .field-control;
                    line-height:normal;

                    @include media-query(desk) {
                        width:160px;
                        padding-top: 14px;
                        padding-bottom: 15px;
                    }
                }
                .coupon button.button {

                    @include media-query(nav-portable) {
                        display:block;
                        float:none !important;
                        width:100%;
                    }
                }
                .coupon button,
                button.button,
                button.button:disabled,
                button.button:disabled[disabled] {
                    @extend .btn;
                    border-radius:0;
                }
                button.button:disabled:hover,
                button.button:disabled:focus,
                button.button:disabled[disabled]:hover,
                button.button:disabled[disabled]:focus {
                    cursor:default;
                    background:$colour-primary;
                }

            }
        }

        tr.cart-subtotal td {
            border-top:1px solid $colour-ui !important;
        }
    }

    a.remove {
        top:6px; left:auto; right:-6px;
        width:24px; height:24px;
        line-height:22px;
        color:#FFF !important;
        font-size:22px;
        font-weight:400 !important;
        background:$colour-quinary;
        transition:color $base-anim-speed, background-color $base-anim-speed;

        @media screen and (min-width:769px)
        {
            &:hover,
            &:focus {
                color:#FFF !important;
                background-color:#d91e18;
            }
        }

        @media screen and (max-width: 768px) {
            top: 8px;
            right: 8px;
        }
    }

    .woocommerce-error,
    .woocommerce-info,
    .woocommerce-message {
        background:$colour-ui;

        @include media-query(nav-portable) {

            a.button,
            button.button {
                margin-left:24px;
            }
        }
    }

    a.button,
    button.button {
        @extend .btn;
        border-radius:0;
    }
}

.woocommerce-store-notice, p.demo_store {
    background-color: $colour-secondary;
}

/*------------------------------------*\
    $WOOCOMMERCE - PRODUCT LISTING
\*------------------------------------*/

.woocommerce ul.products {
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;

    li.product {
        padding:0 24px;
        color:$colour-ui-dark;
        background-color:#ffffff;

        @include media-query(nav-portable) {
            float:none !important;
            margin-bottom:12px !important;
            width:calc(50% -12px) !important;

            &:nth-child(odd) {
                margin-right: 12px;
            }
        }

        a.woocommerce-LoopProduct-link {
            display:flex;
            flex-direction:column;
            flex-wrap:wrap;
            align-items:center;
            flex:1 0 auto;
            position:relative;
            z-index:1;
            margin:0 -24px 12px;
            width:calc(100% + 48px);
            overflow:hidden;

            @media screen and (min-width:769px)
            {
                &:hover,
                &:focus {

                    .price {

                    }
                }
            }

            img {
                display:block;
                float:none;
                margin:0;
                width:100%; height:auto;
                border: rem(1px) solid #e6e6e6;
            }
        }

        .onsale {
            position:absolute;
            top:20px; right:-30px;
            z-index:2;
            margin:0; padding:9px 48px;
            min-width:0; min-height:0;
            line-height:1;
            text-transform:uppercase;
            background-color:$colour-primary;
            border-radius:0;
            transform:rotate(45deg);
        }

        .woocommerce-loop-product__title {
            @include heading-5;
            line-height:1.4;
            display:block;
            margin:0; padding:14px 24px;
            width:100%;
            color:$base-text-colour;
            font-weight:600;
            background-color:$base-background-colour;
            flex:1 0 auto;

            @include media-query(nav-portable) {
                padding: 14px 0;
            }
        }

        .price {
            display: block;
            position: relative;
            font-size: 1.2rem;
            padding: 16px 24px;
            text-transform: uppercase;
            font-weight: $font-weight-semibold;
            overflow: hidden;
            background: $colour-secondary;
            color: white;
            width: 100%;
            text-align: center;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 100%;
                width: 100%;
                height: 3px;
                background-color: $colour-primary;
                transition: transform $base-anim-speed ease-out;
            }

            &:hover {
                background-color: $colour-ui-dark !important;
                color: $colour-ui-light !important;

                &::before {
                    transform: translateY(100%);
                    transition-timing-function: ease-in;
                }
            }

            //del,
            //ins {
            //    display: block;
            //}
            del {
                padding-right:6px;
            }
            ins {
                padding-left:6px;
            }
        }

        .button.add_to_cart_button,
        .button.product_type_grouped,
        .button.product_type_variable {
            margin:0 -24px; padding:12px;
            width:calc(100% + 48px);
            color:$colour-ui-light;
            text-align:center;
            text-transform:uppercase;
            background-color:$colour-secondary;
            border-radius:99em;
            transition:color $base-anim-speed, background-color $base-anim-speed;

            @media screen and (min-width:769px)
            {
                &:hover,
                &:focus {
                    color:$colour-ui-dark;
                    background-color:$colour-tertiary;
                }
            }

            &.loading {
                opacity:1;

                &:after {
                    top:50%; right:24px;
                    margin-top:-8px;
                }
            }

            & + .added_to_cart.wc-forward {
                display:none !important;
            }
        }
    }
}

/*------------------------------------*\
    $WOOCOMMERCE - SINGLE PRODUCT
\*------------------------------------*/

.woocommerce div.product {
    margin:0; padding:0;
    padding-top: 30px;
    background:none;

    @include media-query(desk) {
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
    }

    div.images.woocommerce-product-gallery,
    div.summary {
        float:none;
        margin:0; padding:0;
    }

    div.images.woocommerce-product-gallery {

        @include media-query(desk) {
            padding-right:24px;
            width:60%;
        }

        .woocommerce-product-gallery__wrapper {
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
            margin:0;

            .woocommerce-product-gallery__image {

                &:first-child {
                    padding:0;
                }
                &:not(:first-child) {
                    padding:0;
                    width:25%;
                }
            }
        }
    }

    div.summary {

        @include media-query(desk) {
            padding-left:24px;
            width:40%;
        }
        @include media-query(nav-portable) {
            margin-top:24px;
        }

        .product_title {
            margin-bottom:var(--typography-margin, ($base-spacing-unit / $base-font-size) * 0.5rem);
            color:$colour-ui-dark;
            @media screen and (max-width:768px) {
                padding: 0 24px;
            }
        }

        .stock {
            @media screen and (max-width:768px) {
                padding: 0 24px;
            }
        }

        .price {
            padding:6px 0;
            color:$colour-primary;
            border-top:1px solid $colour-ui; border-bottom:1px solid $colour-ui;
            @media screen and (max-width:768px) {
                padding: 0 24px;
            }
        }

        .wc-pao-addons-container {
            width: 100%;
            @media screen and (max-width:768px) {
                padding: 0 24px;
            }
        }

        .woocommerce-product-details__short-description {
            @media screen and (max-width:768px) {
                padding: 0 24px;
            }
        }
        .product_meta {
            @media screen and (max-width:768px) {
                padding: 0 24px;
            }

             & > span {
                 display:block;

                 span, a {
                     color:$colour-primary;
                     font-weight:600;
                 }
                 a {
                     text-decoration:underline;
                 }
             }
        }
        form.cart {
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;

            .single_variation_wrap,
            .woocommerce-variation-add-to-cart {
                width: 100%;
                display: flex;
            }

            .quantity {
                float:none;
                margin:0;
                width: percentage(1/3);

                input {
                    @extend .field-control;
                }
            }

            .button {
                @extend .btn;
                border-radius: 0;
                width: percentage(2/3);
            }
        }
    }

    .woocommerce-tabs,
    .related.products {
        width:100%;
        @media screen and (max-width:768px) {
            padding: 0 24px;
        }
    }

    .woocommerce-tabs {
        padding-top:48px;

        @include media-query(nav-portable) {
            padding-top:24px;
        }

        ul.tabs {
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
            padding:0; margin-bottom:0;
            border-bottom:2px solid $colour-ui;
            overflow:visible;

            @include media-query(nav-portable) {
                border-bottom:none;
            }

            &:before {
                display:none;
            }

            li {
                display:block;
                padding:0; margin:0 0 -2px;
                background:none;
                border:none;
                border-radius:0;

                @include media-query(nav-portable) {
                    margin-bottom:0;
                    width:100%;
                }

                &:before,
                &:after {
                    display:none;
                }

                a {
                    display:block;
                    padding:12px 24px;
                    color:$colour-ui-dark;
                    font-weight:600;
                    text-transform:uppercase;
                    border-bottom:2px solid $colour-ui;
                    transition:color $base-anim-speed, border-color $base-anim-speed;
                }

                &.active a {
                    color:$colour-primary;
                    border-bottom-color:$colour-primary;
                    cursor:default;
                }
                &:not(.active) a {

                    @media screen and (min-width:769px)
                    {
                        &:hover,
                        &:focus {
                            color:$colour-primary;
                            border-bottom-color:$colour-primary;
                        }
                    }
                }

                &.reviews_tab {
                    display: none;
                }
            }
        }

        .panel {
            padding:32px;
            border:2px solid $colour-ui; border-top:none;

            @include media-query(nav-portable) {
                margin-top:6px; padding:24px;
                border-top:2px solid $colour-ui;
            }

            & > h2,
            .woocommerce-Reviews-title {
                @include heading-3;
                color:$colour-ui-dark;
            }
        }
    }

    .related.products {

        & > h2 {
            @include heading-3;
            color:$colour-ui-dark;
        }
    }

    &.has-post-thumbnail > span.onsale {
        top:30px; left: calc(60% - 24px);
        width: 130px;
        height: 130px;
        border-radius: 0;
        overflow: hidden;
        transform: translateX(-100%);
        font-size:0;
        text-indent:-9999em;
        background:none;

        @include media-query(nav-portable) {
            left:100%;
        }

        &:before {
            content:"Sale!";
            position:absolute;
            top:20px; right:-30px;
            z-index:3;
            margin:0; padding:9px 48px;
            min-width:0; min-height:0;
            line-height:1;
            color:#FFF;
            font-size:13px;
            text-transform:uppercase;
            transform:rotate(45deg);
        }
        &:after {
            content:"";
            position:absolute;
            top:20px; right:-30px;
            z-index:2;
            padding:9px 48px;
            width:130px; height:30px;
            background-color:$colour-primary;
            transform:rotate(45deg);
        }
    }
}

/*------------------------------------*\
    $WOOCOMMERCE - CART
\*------------------------------------*/

.woocommerce-cart-form {

    @include media-query(nav-portable) {
        margin-top:-12px;
    }
}

.woocommerce-cart {
    .main-content h1 {
        @media screen and (max-width:768px) {
            padding: 0 12px;
        }
    }

    table.cart img {
        width:64px;
    }

    .wc-proceed-to-checkout a.checkout-button {
        @extend .btn;
        @extend .btn-full;
        border-radius:0;
    }
}

.cart-collaterals {
    .cart_totals h2 {
        @media screen and (max-width:768px) {
            padding: 0 12px;
        }
    }
}

/*------------------------------------*\
    $WOOCOMMERCE - CHECKOUT
\*------------------------------------*/

.woocommerce form.checkout_coupon {
    padding:32px;
    background:none;
    border:2px solid $colour-ui;
    border-radius:0;

    @include media-query(nav-portable) {
        padding:24px;

        & > p:first-child {
            margin-bottom:12px;
        }

        .form-row {
            float:none;
            margin:0; padding:0;
            width:100%;

            button.button {
                width:100%;
            }
        }
    }

    input[type=text] {
        @extend .field-control;
    }
    button.button {
        @extend .btn;
        border-radius:0;
    }
}

#order_review_heading {
    margin-top:32px;
}

.woocommerce-checkout-payment {
    background:none !important;
    border-radius:0 !important;

    ul.payment_methods {
        padding:32px !important;
        background:$colour-ui !important;
        border-bottom:none !important;

        @include media-query(nav-portable) {
            padding:24px !important;
        }

        li.wc_payment_method {
            position: relative;
            display:block;
            vertical-align: top;

            & + li {
                margin-top:16px !important;
            }

            > input[type=radio] {
                @extend .check-control;
            }
            > label {
                @extend .check-body;
                display:flex;
                flex-direction:row;
                align-items:center;
                position:relative;

                &::before {
                    border-color:rgba(#000, .18);
                    border-radius:100%;
                }
                &::after {
                    border-radius:100%;
                }

                img {
                    padding-left:16px !important;
                    box-sizing:content-box;

                    & + img {
                        padding-left:3px !important;
                    }
                }
            }

            .payment_box {
                margin-bottom:0 !important;
                background:#FFF !important;

                &:before {
                    border-bottom-color:#FFF !important;
                }
            }
        }
    }

    .form-row.place-order {
        padding:32px !important;
        background:none;
        border:2px solid $colour-ui;

        @include media-query(nav-portable) {
            padding:24px 24px 12px !important;
        }

        button.button {
            @extend .btn;
            border-radius:0;
        }
    }
}

.woocommerce-checkout {
    .main-content {
        @media screen and (max-width:768px) {
            padding: 0 12px;
        }
    }
}

/*------------------------------------*\
    $WOOCOMMERCE - WIDGETS (GENERIC)
\*------------------------------------*/

.widget.woocommerce {
    list-style:none;

    .widgettitle {
        @include heading-3;
    }
}

/*------------------------------------*\
    $WOOCOMMERCE - WIDGETS (SHOPPING CART)
\*------------------------------------*/

.widget.woocommerce.widget_shopping_cart .widget_shopping_cart_content {

    ul li.woocommerce-mini-cart-item.mini_cart_item {
        margin-top:12px; padding:0; padding:12px 0;
        border-top:1px solid $colour-ui;

        .remove {
            top:6px; left:auto; right:-6px;
        }

        img {
            display:block;
            width:64px; height:auto;

            &.woocommerce-placeholder {
                border:1px solid $colour-ui;
            }
        }
    }

    .woocommerce-mini-cart__total.total {
        margin:0; padding:12px 0 12px;
        border:none;
    }

    .woocommerce-mini-cart__buttons.buttons {
        display:flex;
        flex-direction:row;
        flex-wrap:nowrap;

        a {
            flex:1 0 auto;
            margin:0; padding:12px 0;
            color:#FFF;
            text-align:center;
            text-transform:uppercase;
            border-radius:0;
            transition:color $base-anim-speed, background-color $base-anim-speed;

            &:first-child {
                margin-right:2px;
                background-color:$colour-primary;
            }
            & + a {
                margin-left:2px;
                background-color:$colour-secondary;
            }

            @media screen and (min-width:769px)
            {
                &:hover,
                &:focus {
                    color:$colour-ui-dark;
                    background-color:$colour-tertiary;
                }
            }
        }
    }
}

#ship-to-different-address {

    @include media-query(nav-portable) {
        font-size:15px;
    }
}

/*------------------------------------*\
    $WOOCOMMERCE - WIDGETS (RECENTLY VIEWED PRODUCTS)
\*------------------------------------*/

.widget.woocommerce.widget_recently_viewed_products ul.product_list_widget {

    li {
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        align-items:baseline;
        justify-content:center;
        padding:0 24px 12px;
        color:$colour-ui-dark;
        background-color:#dce0df;

        &:not(:first-child) {
            margin-top:12px;

            @include media-query(nav-portable) {
                margin-bottom:24px;
            }
        }

        a {
            display:block;
            position:relative;
            z-index:2;
            margin:0 -24px; // padding-bottom:73px;
            width:calc(100% + 48px);

            @media screen and (min-width:769px)
            {
                &:hover,
                &:focus {

                    & + del > span,
                    & + del + ins > span {
                        background-color:$colour-quaternary;
                    }
                }
            }
        }

        img {
            display:block;
            float:none;
            margin:0;
            width:100%; height:auto;

            &.woocommerce-placeholder {
                border:1px solid $colour-ui;
            }
        }

        .product-title {
            @include heading-5;
            line-height:1.4;
            display:block;
            margin:0 0 12px; padding:14px 24px;
            background-color:$colour-ui;
        }

        del, ins {
            display:block;
            position:relative;
            z-index:1;
            margin-top:-73px;

            & > span {
                display:block;
                padding:12px 24px;
                background:$colour-primary;
                transition:background-color $base-anim-speed;
            }
        }
        del {
            color:rgba($colour-ui-light, .32);

            & > span {
                padding-right:6px;
                border-radius:99em 0 0 99em;
            }
        }
        ins {
            color:$colour-ui-light;

            & > span {
                padding-left:6px;
                border-radius:0 99em 99em 0;
                font-weight:700;
            }
        }
    }
}

/*------------------------------------*\
    $WOOCOMMERCE - FORM FIELDS
\*------------------------------------*/

.woocommerce form {

    .form-row {
        margin-left:0 !important; margin-right:0 !important; padding-left:0 !important; padding-right:0 !important;
    }

    .form-row label:not(.woocommerce-form__label-for-checkbox) {
        @extend .field-label;

        .required {
            color:$base-text-colour !important;
        }
    }

    fieldset {
        border:none;

        legend {
            display:none;
        }
    }

    input[type=tel],
    input[type=text],
    input[type=email],
    input[type=number],
    input[type=password],
    textarea {
        @extend .field-control;
    }
    textarea {
        min-height:143px;
    }
    .select2-selection--single,
    select {
        @extend .field-control;
        padding-right:58px;
        background-image:url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M24,28.5L24,28.5L24,28.5L5.3,10.6c-0.5-0.5-1.2-0.4-1.7,0l-3.3,3.2c-0.5,0.5-0.5,1.2,0,1.7l22.8,21.9c0.2,0.2,0.6,0.3,0.9,0.3s0.6-0.1,0.9-0.3l22.8-21.9c0.5-0.5,0.5-1.2,0-1.7l-3.3-3.2c-0.5-0.5-1.2-0.5-1.7,0L24,28.5z"/></svg>');
        background-size:14px;
        background-position:right 20px center;
        background-repeat:no-repeat;

        &::-ms-expand {
            display: none;
        }
    }
    .select2-selection--single {
        height:auto;

        .select2-selection__rendered {
            padding:0;
            line-height:1.57143;
        }
        .select2-selection__arrow {
            display:none;
        }
    }
    label.checkbox,
    label.woocommerce-form__label-for-checkbox {
        @extend .check;

        input[type=checkbox] {
            @extend .check-control;
        }
        span {
            @extend .check-body;
        }
    }
    button.button {
        @extend .btn;
        border-radius:0;
    }
}

.woocommerce form.login,
.woocommerce form.register {
    padding:32px 32px 8px;
    background:$colour-ui;
    border:none;
    border-radius:0;

    @include media-query(nav-portable) {
        padding:24px 24px 8px;
    }
}

#customer_login {

    @include media-query(desk) {
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        justify-content:space-between;
    }

    .u-column1.col-1,
    .u-column2.col-2 {
        display:flex;
        flex-direction:column;
        flex-wrap:nowrap;
        float:none;

        @include media-query(desk) {
            width:calc(50% - 12px);
        }

        form {
            flex:1 0 auto;
            margin:0 0 32px;

            .form-row {
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                align-items:center;
                margin:0; padding:0 0 24px;
            }

            label.woocommerce-form__label-for-checkbox {
                display:inline-block;
                margin-left:24px;

                @include media-query(nav-portable) {
                    margin:24px 24px 0 0;
                }

                span:before {
                    border-color:#FFF;
                }
            }
        }
    }

    @include media-query(desk) {

        .u-column1.col-1 {
            margin-right:12px;
        }
        .u-column2.col-2 {
            margin-left:12px;
        }
    }
}

/*------------------------------------*\
    $WOOCOMMERCE - MY ACCOUNT
\*------------------------------------*/

.woocommerce-MyAccount-navigation ul {
    @extend .sidenav;

    li {
        @extend .sidenav-item;

        a {
            @extend .sidenav-link;
        }

        &.is-active a {
            @extend .is-current;
        }
    }
}

/*------------------------------------*\
    $WOOCOMMERCE - PRICE RANGE SLIDER
\*------------------------------------*/

.widget.woocommerce.widget_price_filter .price_slider_wrapper {

    .ui-widget-content {
        background-color:$colour-secondary;

        .ui-slider-range,
        .ui-slider-handle {
            background-color:$colour-primary;
        }
    }

    .price_slider_amount .button {
        background:none;
        border:2px solid $colour-ui-light;
    }
}

.blockUI {
    z-index: 5 !important;
}

.product-categories {

    > .cat-item {
        border-left: 3px solid #e6e6e6;

        &.current-cat {
            border-left-color: $colour-primary;
        }
    }

    & > .cat-item {
        padding-left: 24px;

        /*
        &:not(:last-child) {
            padding-bottom: 26px;
        }
        */

        a {
            display: inline-block;
            color: #474747;

            &:hover {
                color: $colour-primary;
            }
        }

        & .children {
            padding-top: 16px;

            .cat-item {
                padding-left: 16px;
            }
        }
    }

    .cat-item:not(:last-child) {
        padding-bottom: 16px;
    }

    .current-cat > a {
        color: $colour-primary;
        font-weight: $font-weight-semibold;
        text-decoration: underline;
    }

    .children {
        margin-bottom:0;
    }
}

.woocommerce-mini-cart__empty-message,
.woocommerce.widget_layered_nav_filters > ul,
.woocommerce.widget .woocommerce-widget-layered-nav-list,
.widget_product_tag_cloud .tagcloud {
    border-left: 3px solid #e6e6e6;
    padding-left: 30px;
}

.woocommerce.widget_layered_nav_filters > ul,
.woocommerce.widget .woocommerce-widget-layered-nav-list {
    a {
        color: #474747;

        &:hover {
            color: $colour-primary;
        }
    }
}

.woocommerce ul.product_list_widget {
    // border-top: 1px solid #e6e6e6;

    li {
        border-bottom: 1px solid #e6e6e6;
    }
}

.woocommerce div.product form.cart .variations {
    margin-bottom: 0;
}

.woocommerce div.product form.cart .variations tr {
    display:flex;
    flex-direction:column;
    position:relative;
    z-index:1;
}
    .woocommerce div.product form.cart .variations td {
        padding: 2em;
        background-color: #f2f5f4;
    }
    .woocommerce div.product form.cart .variations tr > td:first-child {
        position:absolute;
        top:0; left:0; right:0;
        z-index:2;
        background:none;
    }
    .woocommerce div.product form.cart .variations tr > td + td {
        padding-top:4em;
    }

.woocommerce div.product form.cart .variations td.label {
    padding-right: 0;
}

.woocommerce div.product form.cart .variations td.value {
    padding-right: 2em;
}

.widget.woocommerce.widget_price_filter .price_slider_wrapper .price_slider_amount {
    display:flex;
    flex-direction:row;
    text-align:left;
    align-items:center;
    justify-content:space-between;

    .price_label {
        order:1;
    }
    .button {
        order:2;
        padding: 0px 22px;
        text-transform: none;
        background:$colour-primary;
        border-radius: 99px;
    }
    .clear {
        display:none !important;
    }
}

.woocommerce div.product div.summary form.cart .single_variation_wrap {
    flex-wrap:wrap;

    .woocommerce-variation.single_variation {
        padding:12px 24px;
        width:100%;
        background-color: #f2f5f4;
        border-top:1px solid rgba(#000, .12);
        box-sizing:border-box;

        .price {
            padding:0;
            border:none;
        }
    }
}

.woocommerce div.product form.cart .reset_variations {
    visibility:hidden !important;
    display: none !important;
}

.woocommerce div.product form.cart td:has( > button.reset_variations ) {
    display: none !important;
}


.woocommerce div.product form.cart .variations td.label {
    line-height:1.2;
}

.up-sells.upsells.products {
    width:100%;
}

.woocommerce-price-suffix {
    display: none;
    padding-left: 6px;
}

.woocommerce ul.order_details li {
    @include media-query(portable) {
        float: none;
        border-bottom: 1px dashed #d3ced2;
        border-right: none;
        padding-top: 1em;
        padding-bottom: 1em;
        margin-right: 0;
    }
}

.woocommerce .woocommerce-message a.button {
    display:block;
    float:none;
    margin-left:0; margin-bottom:12px;
}

.woocommerce-checkout-payment {
    margin-bottom:30px;
}

.woocommerce form .password-input,
.woocommerce-page form .password-input {
    width:100%;
}

.woocommerce-form-login .form-row {
    justify-content:space-between;

    &:before,
    &:after {
        display:none !important;
    }

    .woocommerce-form-login__rememberme {
        margin:0 !important;
    }
    .woocommerce-form-login__submit {
        margin-right:0 !important;
    }
}
