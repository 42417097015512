/*------------------------------------*\
    $LINK-BARE
\*------------------------------------*/

.link-bare {
    color: currentColor;

    &:hover {
        color: $colour-primary;

        .post & {
            color: $colour-ui-light;
        }
    }
}
