/*------------------------------------*\
    $MAIN
\*------------------------------------*/

.main {
    @include grid($base-spacing-unit * 1.5);
}

.main-content,
.main-sidebar {
    @include grid-item($base-spacing-unit * 1.5);
}

.main-content {
    @include media-query(desk) {
        @include grid-width(2/3, $base-spacing-unit * 1.5);
    }

    @include media-query(wide) {
        @include grid-width(3/4, $base-spacing-unit * 1.5);
    }
}

.woo-main-content {
    @include grid-width(3/3, $base-spacing-unit * 1.5);
    margin: auto;
}

.main-sidebar {
    @include media-query(desk) {
        @include grid-width(1/3, $base-spacing-unit * 1.5);
        padding-top: 30px;
    }

    @include media-query(wide) {
        @include grid-width(1/4, $base-spacing-unit * 1.5);
    }

    @media screen and (max-width:768px) {
        padding: 0 24px;
    }
}
