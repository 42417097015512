/*------------------------------------*\
    $CATEGORY
\*------------------------------------*/

.category-list {
    margin-bottom: $base-spacing-unit - 20px;
}

.category {
    display: flex;
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
    color: $colour-ui-dark;
    background-color: $colour-ui;
    font-weight: $font-weight-semibold;
    border-radius: 3px;
}

.category-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: percentage(13/24);
    padding: 20px 27px;
}

.category-title {
    @include font-size(18px);
    line-height:1.2;
    margin: 0;
}

.category-count {
    @include font-size(13px);
    margin-top: auto;
    margin-bottom: 0;
    text-transform: uppercase;
    color: #808080;
}

.category-thumb {
    position: relative;
    width: percentage(11/24);
    background-color: $colour-ui-dark;

    &::before {
        content: "";
        display: block;
        padding-top: 80%;
    }
}

.category-link {
    position: absolute;
    top: 0; left: 0;
    z-index: 2;
    width: 100%; height: 100%;

    &:hover + .category-content .category-title {
        color:$colour-secondary;
    }
}

.category-thumb,
.category-figure {
    border-radius: 0 3px 3px 0;
}
.category-figure {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity $base-anim-speed;

    &.lazyloaded {
        opacity: 0.8;
    }
}
