/*------------------------------------*\
    $ARROW
\*------------------------------------*/

.arrow-wrapper {
    position: relative;

    @include media-query(desk) {
        &#{&} {
            padding-top: 14px;
            padding-bottom: 14px;
        }
    }
}

.arrow-heading {
    @include media-query(desk) {
        margin-bottom: 0;
    }
}

.arrow-prev,
.arrow-next {
    @include font-size(13px);
    padding: 12px 24px 13px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $colour-ui-dark;
    background-color: $colour-ui;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    transition: background-color $base-anim-speed, color $base-anim-speed;

    .icon {
        position: absolute;
        top: 50%;
        width: 8px;
        height: 11px;
        transition: transform $base-anim-speed ease-in-out;
    }

    &:hover {
        background-color: $colour-quaternary;
        color: $colour-ui-light;
    }

    @include media-query(portable) {
        display: none;
    }
}

.arrow-prev {
    left: 0;
    padding-left: 52px;

    .icon {
        left: 36px;
        transform: translate(-50%, -50%) rotate(0.5turn);
    }

    &:hover {
        .icon {
            transform: translate(-110%, -50%) rotate(0.5turn);
        }
    }
}

.arrow-next {
    right: 0;
    padding-right: 52px;

    .icon {
        right: 36px;
        transform: translate(50%, -50%);
    }

    &:hover {
        .icon {
            transform: translate(110%, -50%);
        }
    }
}
