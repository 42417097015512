/*------------------------------------*\
    $CONTAINER
\*------------------------------------*/

// Setup widths and gutters for containers
$container-width:  1140px !default;


/**
 * Contain elements in an arbitrary width
 */

.container {
    max-width: $container-width;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    &.overflox-x {
        overflow-x:auto;
    }
}

.container-sml {
    max-width: 405px;
}

.container-med {
    max-width: 650px;
}
