/*------------------------------------*\
    $SOCIAL
\*------------------------------------*/

.social {
    display: block;
    margin-bottom: 17px;
    width: 20px;
    height: 36px;
    position: relative;
    color: #62736a;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 20px;
    }

    &:hover {
        color: $colour-ui-light;
    }

    @media screen and (min-width: 370px) {
        width: 36px;
    }
}
