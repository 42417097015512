/*------------------------------------*\
    $ICON
\*------------------------------------*/

/**
 * Icon object - used with <svg> icons
 */

.icon {
    fill: currentColor;
    vertical-align: middle;
    display: inline-block;
    width: 16px;
    height: 16px;
}

.icon-sml {
    width: 10px;
    height: 10px;
}
