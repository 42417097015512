/*------------------------------------*\
    $PAGE
\*------------------------------------*/

/**
 * Set font defaults, base colours and high-level page stylings
 */

html {
    font: #{($base-font-size/16px)*1em}/#{$base-line-height/$base-font-size} $base-font-fallback;
    color: $base-text-colour;
    background-color: $base-background-colour;
    overflow-y: scroll;
    min-height: 100%;

    &.wf-active {
        font-family: $base-font;
    }

    &:not(.wf-active) {
        font-size: #{(14px/16px)*1em}
    }
}


/**
 * Set base branded user interactions
 */

::selection {
    background-color: $colour-primary;
    color: #fff;
    text-shadow: none;
}

@mixin focus {
    outline: 2px solid $colour-primary;
    outline-offset: 2px;

    body:not(.has-tabbing-user) & {
        outline: none;
    }
}

:focus {
    @include focus;
}
