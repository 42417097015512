/*------------------------------------*\
    $ADJACENT
\*------------------------------------*/

/**
 * Remove vertical margin
 */

.adjacent {
    margin-bottom: 0;
}

@include media-query(desk) {
    .adjacent-desk {
        margin-bottom: 0;
    }
}
