/*------------------------------------*\
    $GRID
\*------------------------------------*/

// Define grid gutter sizes
$grid-gutter-width:	$base-spacing-unit !default;


// Define mixins
@mixin grid($gutter-width: $grid-gutter-width, $margin-bottom: 0, $mode: flex) {
    margin: 0 0 $margin-bottom (0 - $gutter-width);
    display: $mode;
    flex-wrap: wrap;
}

@mixin grid-width($fraction, $gutter-width: $grid-gutter-width) {
    @if ($fraction == auto) {
        flex: 0 1 auto;
        width: auto;
    } @else {
        @if ($gutter-width != 0px) {
            width: calc(#{percentage($fraction)} - #{$gutter-width});
        } @else {
            width: #{percentage($fraction)};
        }
    }
}

@mixin grid-item($gutter-width: $grid-gutter-width, $fraction: 1) {
    @include grid-width($fraction, $gutter-width);
    margin-left: $gutter-width;
}


/**
 * Base grid object
 */

.grid {
    @include grid;
}

    .grid-item {
        @include grid-item;
    }


/**
 * Alignment
 */

.grid-center {
    justify-content: center;
}

.grid-justify {
    justify-content: space-between;
}

.grid-right {
    justify-content: flex-end;
}

.grid-middle {
    align-items: center;
}

.grid-baseline {
    align-items: baseline;
}

.grid-bottom {
    align-items: flex-end;
}

.grid-item-top {
    align-self: flex-start;
}


/**
 * Disable wrapping
 */

.grid-nowrap {
    flex-wrap: nowrap;
}


/**
 * Gutters
 */

.grid-sml {
    margin-left: -14px;

    > .grid-item {
        @include grid-item(14px, 1/1);
    }
}

.grid-xs {
   margin-left: -8px;

   > .grid-item {
       @include grid-item(8px, 1/1);
   }
}

.grid-med {
   margin-left: (0 - double($base-spacing-unit));

   > .grid-item {
       @include grid-item(double($base-spacing-unit), 1/1);
   }
}



/**
 * Reverse source order
 */

.grid-reverse {
    flex-direction: row-reverse;
}
