/*------------------------------------*\
    $TEAM
\*------------------------------------*/

.team-list {
    @include grid;

    @include media-query(wide) {
        margin-left: -75px;
    }
}

.team {
    @include grid-item;

    @include media-query(lap-only) {
        @include grid-width(1/2);
    }

    @include media-query(lap) {
        &:not(:nth-last-child(-n + 2)) {
            margin-bottom: $base-spacing-unit;
        }
    }

    @include media-query(wide) {
        @include grid-item(75px, 1/2);
    }
}

.team-thumb {
    @include media-query(palm) {
        margin-left: -$base-spacing-unit;
        margin-right: -$base-spacing-unit;
    }
}

.team-title {
    color: $colour-ui-dark;
    margin-bottom: 0 !important;
}

.team-role,
.team-info {
    @include font-size(13px);
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

.team-body {
    color: #808080;

    > :not(:last-child) {
        margin-bottom: halve($base-spacing-unit);
    }

    @include media-query(lap) {
        max-width: 280px;
        margin-left: $base-spacing-unit;
        margin-right: $base-spacing-unit;
    }
}

.team-info {
    display: inline-block;
    width: double($base-spacing-unit);
}

.team-email {
    color: $colour-ui-dark;
    text-decoration: underline;
    font-weight: $font-weight-semibold;

    &:hover {
        color: $colour-primary;
    }
}
