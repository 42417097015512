/*------------------------------------*\
    $HERO
\*------------------------------------*/

.hero-slider {
    position: relative;
    .woocommerce-shop & {
        @media screen and (min-width:769px) {
            margin-top: -142px;
        }
    }

    .slick-arrow {
        background-color: rgba($colour-ui-dark, .4);
        color: rgba($colour-ui-light, .3);

        &:hover {
            color: $colour-ui-light;
        }
    }

    @include media-query(palm) {
        padding: 0 17px 25px 25px;

        .slick-list {
            overflow: visible;
        }
    }

    @include media-query(nav-portable) {
        overflow: hidden;
        transition: transform $base-anim-speed ease-in-out;

        .has-active-nav & {
            transform: translateX(-100%) translateX(70px);

            @media screen and (min-width: 370px) {
                transform: translateX(-300px);
            }
        }
    }
}

.hero {
    /* 63px = height of header, 25px = margin, 53px = height of footer nav */
    height: calc(100vh - #{63px + 25px + 53px});
    height: calc(var(--vh, 1vh) * 100 - #{63px + 25px + 47px + 73px});
    position: relative;
    padding: $base-spacing-unit $base-spacing-unit 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $colour-ui-dark;

    @include media-query(palm) {
        margin-right: 8px;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            background-image: linear-gradient(to top, $colour-ui-dark, rgba($colour-ui-dark, 0));
        }
    }
    @include media-query(desk) {
        &.hero-left::after,
        &.hero-right::after {
            content: "";
            position: absolute;
            top: 0;
            width: 50%;
            height: 100%;
            z-index: 2;
        }
        &.hero-left::after {
            left: 0;
            background-image: linear-gradient(to left, rgba($colour-ui-dark, 0), $colour-ui-dark);
        }
        &.hero-right::after {
            right: 0;
            background-image: linear-gradient(to left, $colour-ui-dark, rgba($colour-ui-dark, 0));
        }
    }

    @include media-query(lap) {
        background-color: #172d23;
        height: calc(100vh - #{63px});
        height: calc(var(--vh, 1vh) * 100 - #{63px + 47});
    }

    @include media-query(nav) {
        /* 203px = height of header */
        height: calc(100vh - 203px);
        height: calc(var(--vh, 1vh) * 100 - 203px);
    }

    @media screen and (min-width: 1150px) {
        /* 142px = height of header */
        height: calc(100vh - 142px - 286px);
        height: calc(var(--vh, 1vh) * 100 - 142px - 210px);
        min-height:500px;

        &.hero-shop {
            height: calc(var(--vh, 1vh) * 100 - 142px - 90px);
        }
    }
}

.hero-figure {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    opacity: 0;
    font-family: 'object-fit: cover';
    transition: opacity $base-anim-speed;

    &.lazyloaded {
        opacity: 0.9;
    }
}

.hero-content {
    position: relative;
    z-index: 3;
    color: $colour-ui-light;

    @include media-query(palm) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    @include media-query(lap) {
        margin: 0 percentage(1/12) 0 auto;
        width: percentage(3/12);
        min-width: 300px;
    }

    @include media-query(desk) {
        h2 {
            text-shadow: 0px 2px 3px $colour-ui-dark;
        }
    }
}

.hero-content-left {
    @include media-query(lap) {
        margin: 0 auto 0 percentage(1/12);
    }
}

.hero-title,
.hero-action,
.hero-footer {
    .slick-initialized & {
        transition: opacity 500ms, transform $base-anim-speed;
        opacity: 0;
        transform: translateY(5px);
    }

    .slick-active & {
        opacity: 1;
        transform: none;
    }
}

.hero-title {
    @include font-size(35px, 40px);
    margin-bottom: 50px;
    font-weight: $font-weight-bold;

    span {
        @include font-size(16.5px, $base-spacing-unit);
        margin-bottom: 20px;
        display: block;
        font-weight: $font-weight-semibold;
        text-transform: uppercase;
    }

    .slick-active & {
        @include media-query(lap) {
            transition-delay: 500ms;
        }
    }

    @include media-query(palm) {
        @include font-size(26px, false);
    }
}

.hero-search-title {
    margin-bottom: 0;
    padding: 20px;
    background-color: rgba(0,0,0,0.6);
    span {
        display: inline-block;
        font-size: inherit;
        text-transform: capitalize;
        font-weight: 300;
        margin: 0;
    }
}
.hero-search {
    display: flex;
    flex-direction: row;
    width: auto;
    float: right;
    background-color: $colour-primary;
    padding: 10px;
    border-left: 30px solid $colour-secondary;

    .hero-search-terms {
        position: relative;
        width: 100%;
        background-color: #1B6633;
        border: none;
        padding: 10px;
        text-transform: uppercase;
    }
    .hero-search-btn {
        margin-left: 10px;
    }
}

.search-icon-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: rgba(44,75,32,0.7);
    .hero-search-icons {
        display: flex;
        flex-direction: row;
        @media screen and (max-width:768px) {
            flex-direction: column;
        }
        .hero-search-icon {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1;
            padding: 10px 30px;
            color: #ffffff;
            text-transform: uppercase;
            font-weight: 600;
            line-height: 1.4em;
            .icon {
                width: 90px;
                height: 60px;
                color: #ffffff;
                @media screen and (max-width:768px) {
                    min-width: 90px;
                }
            }
            p {
                margin: 0;
            }
            &__uploaded {
                width: 90px;
                height: 60px;
                svg {
                    max-width: 100%;
                    max-height: 100%;
                    path {
                        fill: #ffffff !important;
                    }
                }
            }
        }
    }
    @media screen and (max-width:768px) {
        display: none;
    }
}

.hero-action {
    .slick-active & {
        transition-delay: 125ms;

        @include media-query(lap) {
            transition-delay: 500ms + 125ms;
        }
    }

    @include media-query(palm) {
        text-align: center;
    }
}

.hero-footer {
    margin-top: auto;
    text-align: center;

    @include media-query(lap) {
        display: none;
    }

    .slick-active & {
        transition-delay: 250ms;

        @include media-query(lap) {
            transition-delay: 500ms + 250ms;
        }
    }
}

.hero-link {
    position:absolute;
    top:0; left:0; right:0; bottom:0;
    z-index:5;
}

/*------------------------------------*\
    $HERO BRANCH
\*------------------------------------*/

.hero-branch {

    @include media-query(desk) {
        min-height:400px;
    }
}
