/*------------------------------------*\
    $CTAS
\*------------------------------------*/

.ctas {
    margin-bottom: $base-spacing-unit;

    @include media-query(desk) {
        // margin-top: double($base-spacing-unit);
        // margin-bottom: double($base-spacing-unit);
    }
}

.ctas-item {
    margin-bottom: 9px;
}

.ctas-item-med {
    @include media-query(desk) {
        width: 50%;
    }
}

.ctas-link {
    @include font-size(18px);
    position: relative;
    display: block;
    padding: 22px 70px 22px 48px;
    background-color: #ebebeb;
    color: $colour-ui-dark;
    transition: background-color $base-anim-speed, color $base-anim-speed;

    .icon {
        position: absolute;
        top: 50%;
        right: 46px;
        width: 9px;
        height: 17px;
        transform: translate(50%, -50%);
    }
}
a.ctas-link:hover {
    color: $colour-ui-light;
    background-color: $colour-quaternary;
}
