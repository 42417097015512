/*------------------------------------*\
    $TABLE
\*------------------------------------*/

.wrapper_table {
    overflow-x:auto;

    table {
        min-width:600px;
    }
}

.table {
    td,
    th {
        border-bottom: 1px solid #dbdbdb;
        padding: 0 36px;
    }
}
