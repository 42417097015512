/*------------------------------------*\
    $SLIDER
\*------------------------------------*/

.slider {
    position: relative;
    overflow: hidden;
    padding-right: 60px;

    .slick-arrow {
        top: 0;
        bottom: 0;
        transform: none;
        height: auto;
        background-color: rgba($colour-ui-dark, .4);
        color: $colour-ui-light;
        transition: background-color $base-anim-speed;

        &:hover {
            background-color: rgba($colour-ui-dark, .6);
        }
    }

    .lazyload-wrapper {
        margin-bottom: 0;
    }

    .slick-list {
        overflow: visible;
    }
}
